import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageDetailComponent } from '../../image-detail/image-detail.component';
import { ModalController, NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReviewWriteComponent } from '../../user/review-write/review-write.component';
import { Review } from 'src/app/core/models/review.model';
import { DbService } from 'src/app/core/services/db.service';
import { increment } from 'firebase/firestore';

@Component({
  selector: 'app-review-item',
  templateUrl: './review-item.component.html',
  styleUrls: ['./review-item.component.scss'],
})
export class ReviewItemComponent implements OnInit {
  @Input() item: Review | any;
  @Input() type: 'myReview' | 'expert' | 'list';
  @Output() reviewDeleted = new EventEmitter<void>();

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private navController: NavController,
    private db: DbService
  ) {}

  ngOnInit() {}

  //이미지 상세모달
  async imgDetailModal(data: Review['images'], idx: number) {
    const items = data.map((item) => ({
      image: item,
      videoCheck: false,
    }));

    const modal = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: { item: items, idx },
    });

    await modal.present();
  }

  //삭제
  deleteAlert() {
    this.alertService
      .cancelOkBtn('리뷰를 삭제하시겠습니까?')
      .then(async (ok) => {
        if (ok) {
          await this.db.updateAt(`reviews/${this.item.id}`, {
            isDelete: true,
          });

          const newRating = await this.storeAvgUpdate();
          await this.db.updateAt(`members/${this.item.expertUuid.uuid}`, {
            rating: newRating,
            reviewCount: increment(-1),
          });
          this.alertService.presentToast('리뷰를 삭제 하였습니다.');
          this.reviewDeleted.emit();
        }
      });
  }

  //수정
  async goEditReview() {
    const modal = await this.modalController.create({
      component: ReviewWriteComponent,
      componentProps: { item: this.item, type: 'review' },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.item.rating = data.rating;
      this.item.content = data.content;
      this.item.images = data.images;
    }
  }

  // 리뷰 평점
  async storeAvgUpdate() {
    // 해당 전문가 리뷰 데이터 가져오기
    const reviews = await this.db.toCollection$(`reviews`, (ref) =>
      ref
        .where('expertUuid', '==', this.item.expertUuid.uuid)
        .where('id', '!=', this.item.id)
        .where('isDelete', '==', false)
    );
    const avg = reviews.map((data) => data.rating); // 별점만 배열로 모으기
    const ratingAvg = this.calculateAverage(avg); // 평균 구하기
    return ratingAvg;
  }

  // 별점 평균 구하기
  calculateAverage(numbers: number[]) {
    if (numbers.length === 0) {
      return 0; // 리뷰가 없을 경우 0 반환
    }

    const sum = numbers.reduce((a, b) => a + b, 0);
    const average = sum / numbers.length;
    return Math.round(average * 10) / 10; // 소수점 첫째 자리까지 반올림
  }
}
