import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { ImageService } from 'src/app/core/services/image.service';
import { Clipboard } from '@capacitor/clipboard';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-profile-detail-user',
  templateUrl: './profile-detail-user.component.html',
  styleUrls: ['./profile-detail-user.component.scss'],
})
export class ProfileDetailUserComponent implements OnInit {
  member: Member;
  profile: string = '';
  memberForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
  });
  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private imageService: ImageService,
    private auth: AuthService,
    private db: DbService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    this.memberForm.get('name').setValue(this.member.name);
    if (this.member.profile) {
      this.profile = this.member.profile;
    }
    console.log('member', this.member);
  }

  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }

  //사진선택
  async addImg() {
    const imgStatus = this.profile
      ? this.imageService.selectCameraDel('profile')
      : this.imageService.selectCamera('profile');

    try {
      const data = await imgStatus;
      if (data) {
        await this.loadingService.load();
        this.profile = data;
      } else {
        this.profile = '';
      }
      this.loadingService.hide();
    } catch {
      this.loadingService.hide();
    }
  }

  //복사
  async copyToast(userCode: Member['userCode']) {
    await Clipboard.write({
      string: userCode,
    });
    this.alertService.presentToast('추천코드가 복사 되었습니다.');
  }

  // [저장하기]
  async save() {
    const member = await this.auth.getUser();
    await this.db.updateAt(`members/${member.uuid}`, {
      profile: this.profile || '',
      name: this.member.name,
    });
    this.navController.pop();
    this.alertService.presentToast('프로필을 수정 했습니다.');
  }
}
