import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-join-expert-complete',
  templateUrl: './join-expert-complete.component.html',
  styleUrls: ['./join-expert-complete.component.scss'],
})
export class JoinExpertCompleteComponent implements OnInit {
  constructor(private navController: NavController) {}

  ngOnInit() {}

  goHome() {
    this.navController.navigateRoot('/tabs/home', {
      animationDirection: 'forward',
    });
  }
}
