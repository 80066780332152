import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { MbscEventcalendarOptions, localeKo } from '@mobiscroll/angular';
import { CalendarPopComponent } from '../calendar-pop/calendar-pop.component';
import { RequestService } from 'src/app/core/services/request.service';
import * as dayjs from 'dayjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-application-date',
  templateUrl: './application-date.component.html',
  styleUrls: ['./application-date.component.scss'],
})
export class ApplicationDateComponent implements OnInit {
  selectedDate: string = ''; // 선택한 시공일
  selectedOption: string = '';
  localeKo = localeKo;
  eventSettings: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    view: {
      calendar: { type: 'month' },
    },
  };

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private requestService: RequestService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // 저장된 데이터가 있다면 불러오기
    const reservation = this.requestService.request;
    console.log('reservation', reservation);

    if (reservation.hopeDate) {
      this.selectedOption =
        reservation.hopeDate === '협의 후 결정' ? 'talk' : 'select';
      console.log('selectedOption', this.selectedOption);

      this.selectedDate = reservation.hopeDate;
      console.log('selectedDate', this.selectedDate);
    }
  }

  //달력팝업
  async openCalendar() {
    const modal = await this.modalController.create({
      component: CalendarPopComponent,
      cssClass: 'calendar-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log('data', data);
    if (!data.selectedDate) {
      this.selectedDate = '';
    }

    if (data) {
      this.selectedDate = new Date(data.selectedDate).toISOString();
      this.selectedOption = 'select';
      console.log('selectedDate', this.selectedDate);
    }
  }

  //이전
  goBack() {
    const reservation = this.requestService.request;
    reservation.hopeDate =
      this.selectedOption === 'select' ? this.selectedDate : '협의 후 결정';
    this.navController.pop();
  }

  // 다음
  goAreaRange() {
    const reservation = this.requestService.request;
    reservation.hopeDate =
      this.selectedOption === 'select' ? this.selectedDate : '협의 후 결정';

    const topId = this.activatedRoute.snapshot.queryParams['topId'];
    const subId = this.activatedRoute.snapshot.queryParams['subId'];
    const requestType = this.activatedRoute.snapshot.queryParams['requestType'];
    const expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.navController.navigateForward('/application-area', {
      queryParams: {
        topId,
        subId,
        requestType,
        expertUuid,
      },
    });
  }
}
