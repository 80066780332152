import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { ImageService } from 'src/app/core/services/image.service';
import { Request } from 'src/app/core/models/request.model';
import { DbService, docJoin } from 'src/app/core/services/db.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Member } from 'src/app/core/models/member.model';
import { lastValueFrom, take } from 'rxjs';
import { ImageDetailComponent } from '../../image-detail/image-detail.component';
import { SubCategory, TopCategory } from 'src/app/core/models/category.model';
import { Estimate } from 'src/app/core/models/estimate.model';
import { CommonService } from 'src/app/core/services/common.service';
import { arrayUnion, increment } from 'firebase/firestore';
import { ChatService } from 'src/app/core/services/chat.service';
import { FavoriteEstimate } from 'src/app/core/models/favorite-estimate.model';
import { PointHistory } from 'src/app/core/models/point-history.model';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-estimate-request-detail',
  templateUrl: './estimate-request-detail.component.html',
  styleUrls: ['./estimate-request-detail.component.scss'],
})
export class EstimateRequestDetailComponent implements OnInit {
  request: Request | any;
  member: Member;
  requestId: Request['id'];
  estimate: Estimate;
  serviceName: string;
  expertName: Member['activityName'];
  readType: 'readType' | 'bookMark'; // readType => 거래상세 bookMark => 즐겨찾기
  favoriteEstimateId: FavoriteEstimate['id'];
  viewType: 'estimate';
  images: string[] = Array(6).fill(null);
  memberForm = new FormGroup({
    price: new FormControl('', [Validators.required, Validators.minLength(2)]),
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(1000),
    ]),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private imgService: ImageService,
    private navController: NavController,
    private alertService: AlertService,
    private db: DbService,
    private auth: AuthService,
    private modalController: ModalController,
    private commonService: CommonService,
    private chatService: ChatService
  ) {}

  async ngOnInit() {
    const parms = this.activatedRoute.snapshot.queryParams;
    this.requestId = parms['requestId'];
    this.serviceName = parms['serviceName'];
    this.expertName = parms['expertName'];
    this.viewType = parms['viewType'];
  }

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    this.readType = this.activatedRoute.snapshot.queryParams['readType']; // 거래내역에서 진입시 읽기모드
    this.favoriteEstimateId =
      this.activatedRoute.snapshot.queryParams['favoriteEstimateId']; // 즐겨찾기 견적서
    await this.getRequest();

    if (this.readType === 'readType') {
      this.estimate = await this.db.toDoc$(
        `estimates/${this.request?.estimateId}`
      );
    } else {
      this.estimate = await this.db.toDoc$(
        `estimates/${this.favoriteEstimateId}`
      );
    }
  }

  // id에 따른 요청서 데이터 불러오기
  async getRequest() {
    this.request = await lastValueFrom(
      this.db
        .doc$(`requests/${this.requestId}`)
        .pipe(docJoin(this.db.firestore, 'uuid', 'members'), take(1))
    );

    if (this.request.expertUuid) {
      const expert: Member = await lastValueFrom(
        this.db.doc$(`members/${this.request.expertUuid}`).pipe(take(1))
      );

      let categoryPairs: string[] = [];
      if (expert) {
        for (const serviceId of expert.services) {
          const sub: SubCategory | null = await lastValueFrom(
            this.db.doc$(`subCategories/${serviceId}`).pipe(take(1))
          );
          if (sub) {
            let categoryName = '';

            if (sub.topCategoryId) {
              const topCategory: TopCategory | null = await lastValueFrom(
                this.db.doc$(`topCategories/${sub.topCategoryId}`).pipe(take(1))
              );
              if (topCategory) {
                categoryName = `${topCategory.name} <span class="icon">></span> ${sub.name}`;
              }
            }
            categoryPairs.push(categoryName);
          }
        }
      }
      this.request.categoryName = categoryPairs.join('\n');
    } else {
      const sub = await this.db.toDoc$(
        `subCategories/${this.request.subCategoryId}`
      );
      this.request.subCategoryName = sub.name;
    }
  }

  // 거래 삭제
  deleteBtn() {
    this.alertService
      .cancelOkBtn('거래내역을 삭제 하시겠습니까?\n삭제 시 복구 불가합니다.')
      .then(async (ok) => {
        if (ok) {
          await this.db.updateAt(`requests/${this.requestId}`, {
            deleteExperts: arrayUnion(this.member.uuid),
          });
          this.navController.pop();
          this.alertService.presentToast('거래 내역을 삭제하였습니다.');
        }
      });
  }

  // 이미지 추가
  async addImg(index: number) {
    const imgUrl = await this.imgService.selectCamera('estimate');
    this.images[index] = imgUrl;
  }

  // 이미지 삭제
  removeImg(index: number) {
    this.images[index] = null;
  }

  //즐겨 찾기 견적
  goMyEstimate() {
    this.navController.navigateForward('/my-estimate', {
      queryParams: {
        type: 'write',
      },
    });
  }

  // 이미지 디테일
  async openImgModal(data, idx: number) {
    const items = data.map((item) => ({
      image: item,
      videoCheck: false,
    }));

    const modal = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: { item: items, idx },
    });

    await modal.present();
  }

  // 견적서 보내기
  async sendEstimate() {
    // 보유 포인트 있을 때
    if (this.member.point + this.member.bonusPoint >= 3000) {
      const emptyImages = this.images.filter(
        (img) => img != null && img.trim() !== ''
      );

      let estimate: Estimate = {
        id: this.commonService.generateFilename(),
        uuid: this.member.uuid,
        acceptUuid: this.request.uuid.uuid,
        dateCreated: new Date().toISOString(),
        requestId: this.requestId,
        price: this.estimate
          ? Number(this.estimate.price)
          : Number(this.memberForm.get('price').value),
        content: this.estimate
          ? this.estimate.content
          : this.memberForm.get('detail').value,
        images: this.estimate ? this.estimate.images : emptyImages,
        isDelete: false,
        isComplete: false,
        isRead: false,
        checkSwitch: false,
        confirmEstimate: false,
        confirmDate: '',
        isRefund: false,
      };

      let pointHistory: PointHistory = {
        id: this.commonService.generateFilename(),
        uuid: this.member.uuid,
        paymentId: '',
        dateCreated: new Date().toISOString(),
        point: 0,
        bonusPoint: 0,
        usePoint: Number(3000),
        price: 0,
        type: '포인트 사용',
        acceptUuid: this.request.uuid.uuid,
      };

      await Promise.all([
        this.db.updateAt(`estimates/${estimate.id}`, estimate),
        this.db.updateAt(`requests/${this.requestId}`, {
          estimateCount: increment(1),
          senders: arrayUnion(this.member.uuid),
        }),
        this.db.updateAt(`pointHistories/${pointHistory.id}`, pointHistory),
        this.updatePoints(),
        this.sendAlarm(),
      ]);

      this.chatService
        .createChat(this.requestId, estimate.id, this.request.uuid.uuid)
        .then(async (data) => {
          await this.chatService.sendMessage(data, 'estimateId', estimate.id); // 견적서 발송
          this.navController.navigateRoot('/chat-detail', {
            animationDirection: 'forward',
            queryParams: {
              chatId: data,
              partnerUuid: this.request.uuid.uuid,
            },
          });
        });
    }
    // 보유 포인트 부족한 경우
    else {
      this.alertService
        .cancelOkBtn(
          '현재 보유한 포인트가 부족합니다. 포인트를 충전하시겠습니까?'
        )
        .then((ok) => {
          if (ok) {
            this.navController.navigateForward('/point-charge');
          }
        });
    }
  }

  // 알림 전송
  async sendAlarm() {
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: this.request.uuid.uuid,
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '견적서 도착',
      content: '견적서가 도착하였습니다. 확인해주세요.',
      url: '/tabs/estimate',
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }

  // 포인트 업데이트
  async updatePoints() {
    const totalPointsToDeduct = 3000;
    let { point, bonusPoint } = this.member;

    // point에서 전액 차감 가능할 때
    if (point >= totalPointsToDeduct) {
      point -= totalPointsToDeduct;
    }
    // point에서 차감 후 부족한 금액을 bonusPoint에서 차감
    else {
      const remainingToDeduct = totalPointsToDeduct - point;
      point = 0; // 모든 point를 사용
      bonusPoint -= remainingToDeduct; // 부족한 금액을 bonusPoint에서 차감
    }

    await this.db.updateAt(`members/${this.member.uuid}`, {
      point: point,
      bonusPoint: bonusPoint,
    });
    this.member.point = point;
    this.member.bonusPoint = bonusPoint;
  }

  //이전
  goBack() {
    this.navController.pop();
  }
}
