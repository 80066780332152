import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { InsurancePayment } from 'src/app/core/models/insurance-payment.model';
import { InsuranceRefund } from 'src/app/core/models/insurance-refund.model';
import { Member } from 'src/app/core/models/member.model';
import { DbService, docJoin } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-small-info-card',
  templateUrl: './small-info-card.component.html',
  styleUrls: ['./small-info-card.component.scss'],
})
export class SmallInfoCardComponent implements OnInit {
  @Input() id: InsurancePayment['id'] | InsuranceRefund['id'] | Member['uuid']; // 전문가uuid;
  @Input() listType: 'payment' | 'refund' | 'chatPayment';
  @Input() serviceName: boolean; // 서비스명이 들어가는지 안들어가는지
  @Input() service: string; // listType === 'chatPayment'일때만
  @Input() ellipsis: boolean; // 보험내역 리스트에서는 말줄임 필요
  data: (InsurancePayment | any) | (InsuranceRefund | any) | (Member | any);

  constructor(private db: DbService) {}

  async ngOnInit() {
    console.log('listType', this.listType);

    if (this.listType === 'payment') {
      this.data = await lastValueFrom(
        this.db
          .doc$(`insurancePayments/${this.id}`)
          .pipe(
            docJoin(this.db.firestore, 'uuid', 'members'),
            docJoin(this.db.firestore, 'expertUuid', 'members'),
            take(1)
          )
      );
    } else if (this.listType === 'refund') {
      this.data = await lastValueFrom(
        this.db
          .doc$(`insuranceRefunds/${this.id}`)
          .pipe(
            docJoin(this.db.firestore, 'uuid', 'members'),
            docJoin(this.db.firestore, 'paymentId', 'insurancePayments'),
            take(1)
          )
      );
    } else if (this.listType === 'chatPayment') {
      this.data = await this.db.toDoc$(`members/${this.id}`);
    }

    console.log('data', this.data);
  }
}
