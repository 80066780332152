import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateFormat',
  pure: false,
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string): string {
    const now = dayjs();
    const dateObj = dayjs(date);
    const diffSeconds = now.diff(dateObj, 'seconds');
    const diffMinutes = now.diff(dateObj, 'minutes');
    const diffHours = now.diff(dateObj, 'hours');
    const diffDays = now.diff(dateObj, 'days');
    const diffMonths = now.diff(dateObj, 'months');

    if (diffSeconds < 60) {
      return '방금 전';
    } else if (diffMinutes < 60) {
      return `${diffMinutes}분 전`;
    } else if (diffHours < 24) {
      return `${diffHours}시간 전`;
    } else if (diffDays < 30) {
      return `${diffDays}일 전`;
    } else if (diffDays < 360) {
      return `${diffMonths}개월 전`;
    } else {
      return dayjs(date).format('YYYY.MM.DD');
    }
  }
}
