import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { increment } from 'firebase/firestore';
import { Review } from 'src/app/core/models/review.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService } from 'src/app/core/services/db.service';
import { ImageService } from 'src/app/core/services/image.service';
import { Notification } from 'src/app/core/models/notification.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { lastValueFrom, map, take } from 'rxjs';
import { SubCategory } from 'src/app/core/models/category.model';
import { Request } from 'src/app/core/models/request.model';

@Component({
  selector: 'app-review-write',
  templateUrl: './review-write.component.html',
  styleUrls: ['./review-write.component.scss'],
})
export class ReviewWriteComponent implements OnInit {
  @Input() item: Review | any;
  @Input() type: 'review' | 'chat';
  @Input() expertUuid: Member['uuid'];
  @Input() requestId: Request['id'];
  @Input() subCategoryId: SubCategory['id'];

  member: Member;
  rating: number;
  images: string[] = Array(6).fill(null);

  textForm = new FormGroup({
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2000),
    ]),
  });

  constructor(
    private modalController: ModalController,
    private imgService: ImageService,
    private alertService: AlertService,
    private db: DbService,
    private commonService: CommonService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.member = await this.auth.getUser();
    console.log('itemitem', this.item);

    if (this.item) {
      this.textForm.setValue({
        detail: this.item.content,
      });
      this.images = this.item.images
        ? this.item.images.slice(0, 6)
        : Array(6).fill(null);
      this.rating = this.item.rating;
    }
    if (this.images.length < 6) {
      this.images = [
        ...this.images,
        ...Array(6 - this.images.length).fill(null),
      ];
    }
  }

  //모달닫기
  closeModal() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.modalController.dismiss();
        }
      });
  }

  // 이미지 추가
  async addImg(index: number) {
    const imgUrl = await this.imgService.selectCamera('portfolio');
    this.images[index] = imgUrl;
  }

  // 이미지 삭제
  removeImg(index: number) {
    this.images[index] = null;
  }

  //완료
  save() {
    this.alertService
      .cancelOkBtn(
        this.item
          ? '입력한 내용으로 리뷰를 수정하시겠어요?'
          : `리뷰를 등록하시겠습니까?`
      )
      .then(async (ok) => {
        const expertUuid =
          this.type === 'chat' ? this.expertUuid : this.item.expertUuid.uuid;
        if (ok) {
          const emptyImages = this.images.filter(
            (img) => img != null && img.trim() !== ''
          );

          let review: Review = {
            id: this.item
              ? this.item.id
              : this.commonService.generateFilename(),
            requestId: this.item ? this.item.requestId : this.requestId,
            uuid: this.member.uuid,
            expertUuid: expertUuid,
            dateCreated: this.item
              ? this.item.dateCreated
              : new Date().toISOString(),
            subCategoryId: this.item
              ? this.item.subCategoryId
              : this.subCategoryId,
            rating: this.rating,
            content: this.textForm.get('detail').value,
            isDelete: false,
            images: emptyImages,
            isAdminWrite: false,
            name: '',
            profile: '',
          };
          await this.db.updateAt(`reviews/${review.id}`, review);
          const expert = await this.db.toDoc$(`members/${expertUuid}`);
          const newRating = await this.storeAvgUpdate();
          await this.db.updateAt(`members/${expertUuid}`, {
            rating: newRating,
            reviewCount: this.item ? expert.reviewCount : increment(1),
          });

          if (!this.item) {
            this.sendAlarm();
          }

          if (this.type === 'chat') {
            this.modalController.dismiss(true);
          } else {
            this.modalController.dismiss({
              rating: this.rating,
              content: this.textForm.get('detail').value,
              images: emptyImages,
            });
          }

          this.alertService.presentToast(
            `리뷰를 ${this.item ? '수정' : '등록'}${
              this.item ? '했' : '하였'
            }습니다.`
          );
        }
      });
  }

  // 전문가에게 알림 전송
  async sendAlarm() {
    const expertUuid =
      this.type === 'chat' ? this.expertUuid : this.item.expertUuid.uuid;
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: expertUuid,
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '리뷰 등록',
      content: '새로운 리뷰가 등록되었습니다.',
      url: `/my-review-list?type=expert`,
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }

  // 리뷰 평점
  async storeAvgUpdate() {
    const expertUuid =
      this.type === 'chat' ? this.expertUuid : this.item.expertUuid.uuid;
    // 해당 전문가 리뷰 데이터 가져오기
    const reviews = await lastValueFrom(
      this.db
        .collection$(`reviews`, (ref) =>
          ref
            .where('expertUuid', '==', expertUuid)
            .where('isDelete', '==', false)
        )
        .pipe(
          map((reviews: Review[]) => {
            if (!this.item) {
              return reviews;
            } else {
              return reviews.filter(
                (review: Review) => review.id !== this.item.id
              );
            }
          }),
          take(1)
        )
    );
    console.log('전문가 전체 리뷰', reviews);

    const avg = reviews.map((data) => data.rating); // 별점만 배열로 모으기
    avg.push(this.rating); // 현재 작성하는 리뷰 별점 배열에 추가
    const ratingAvg = this.calculateAverage(avg); // 평균 구하기
    return ratingAvg;
  }

  // 별점 평균 구하기
  calculateAverage(numbers) {
    if (numbers.length === 0) {
      return 0; // 리뷰가 없을 경우 0 반환
    }

    const sum = numbers.reduce((a, b) => a + b, 0);
    const average = sum / numbers.length;
    return Math.round(average * 10) / 10; // 소수점 첫째 자리까지 반올림
  }
}
