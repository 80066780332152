import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { InsurancePayment } from 'src/app/core/models/insurance-payment.model';
import { InsuranceRefund } from 'src/app/core/models/insurance-refund.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService } from 'src/app/core/services/db.service';
import { Notification } from 'src/app/core/models/notification.model';
import * as dayjs from 'dayjs';
import { increment } from 'firebase/firestore';

@Component({
  selector: 'app-insurance-refund-application',
  templateUrl: './insurance-refund-application.component.html',
  styleUrls: ['./insurance-refund-application.component.scss'],
})
export class InsuranceRefundApplicationComponent implements OnInit {
  payment: InsurancePayment;
  options: string;
  refundptions: any = {
    header: '옵션',
    translucent: true,
  };
  agree: boolean = false;
  textForm = new FormGroup({
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(200),
    ]),
  });

  constructor(
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private db: DbService,
    private commonService: CommonService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    await this.getData();
  }

  // 결제
  async getData() {
    const paymentId = this.activatedRoute.snapshot.queryParams['paymentId'];
    if (paymentId) {
      this.payment = await this.db.toDoc$(`insurancePayments/${paymentId}`);
      console.log('payment', this.payment);
    }
  }

  //환불 약관보기
  goTerms() {
    this.navController.navigateForward('/refund-term');
  }

  //환불신청
  async goApplyRefund() {
    const member = await this.auth.getUser();
    this.alertService
      .cancelOkBtn(
        '환불 신청 요청하시겠습니까?\n 시공 진행되지 않은 경우에만 관리자 확인 후 환불 가능합니다.'
      )
      .then(async (ok) => {
        if (ok) {
          let refund: InsuranceRefund = {
            id: this.commonService.generateFilename(),
            paymentId: this.payment.id,
            uuid: member.uuid,
            dateCreated: new Date().toISOString(),
            reason: this.options,
            reasonDetail: this.textForm.get('detail').value,
            price: this.payment.price,
            isConfirm: false,
            confirmDate: '',
          };

          // 관리자 대시보드 업데이트
          const id = dayjs().format('YYYYMMDD');
          const statisticsData = await this.db.toDoc$(`statistics/${id}`);

          await Promise.all([
            this.db.updateAt(`insuranceRefunds/${refund.id}`, refund),
            this.db.updateAt(`insurancePayments/${this.payment.id}`, {
              isRefund: true,
            }),
            this.db.updateAt(`statistics/${id}`, {
              id,
              date: {
                year: dayjs().format('YYYY'),
                month: dayjs().format('MM'),
                date: dayjs().format('DD'),
              },
              joinMembersCount: statisticsData
                ? statisticsData.joinMembersCount
                : 0,
              exitMembersCount: statisticsData
                ? statisticsData.exitMembersCount
                : 0,
              pointPaymentCount: statisticsData
                ? statisticsData.pointPaymentCount
                : 0,
              pointRefundCount: statisticsData
                ? statisticsData.pointRefundCount
                : 0,
              insurancePaymentCount: statisticsData
                ? statisticsData.insurancePaymentCount
                : 0,
              insuranceRefundCount: increment(this.payment.price),
            }),
            this.sendAlarm(),
          ]);

          this.navController.pop();
          this.alertService.presentToast('환불 신청이 완료되었습니다.');
        }
      });
  }

  // 관리자에게 알림보내기
  async sendAlarm() {
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: 'admin',
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '보험료 환불 요청',
      content: '보험료 환불 요청이 접수 되었습니다.',
      url: '/quote-refund-list',
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }

  // 뒤로가기
  close() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }
}
