import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/core/services/db.service';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-alarm-detail',
  templateUrl: './alarm-detail.component.html',
  styleUrls: ['./alarm-detail.component.scss'],
})
export class AlarmDetailComponent implements OnInit {
  notification: Notification;

  constructor(private activatedRoute: ActivatedRoute, private db: DbService) {}

  async ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams['id'];
    this.notification = await this.db.toDoc$(`notifications/${id}`);
  }
}
