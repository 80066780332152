import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { lastValueFrom, take } from 'rxjs';
import { Admin } from 'src/app/core/models/admin.model';
import { InsurancePayment } from 'src/app/core/models/insurance-payment.model';
import { InsuranceRefund } from 'src/app/core/models/insurance-refund.model';
import { DbService, docJoin } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-insurance-detail',
  templateUrl: './insurance-detail.component.html',
  styleUrls: ['./insurance-detail.component.scss'],
})
export class InsuranceDetailComponent implements OnInit {
  @Input() item: any;
  @Input() type: any;
  @Input() id: InsurancePayment['id'] | InsuranceRefund['id'];
  @Input() listType: 'payment' | 'refund';
  admin = { refund: '', info: '' };
  data: (InsurancePayment | any) | (InsuranceRefund | any);

  constructor(
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private db: DbService
  ) {}

  async ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    const admin = await this.db.toDoc$(`admin/terms`);
    this.admin = {
      refund: admin.insuranceRefund,
      info: admin.insuranceInfo,
    };

    console.log('admin', this.admin);

    if (this.listType === 'payment') {
      this.data = await lastValueFrom(
        this.db
          .doc$(`insurancePayments/${this.id}`)
          .pipe(
            docJoin(this.db.firestore, 'uuid', 'members'),
            docJoin(this.db.firestore, 'expertUuid', 'members'),
            take(1)
          )
      );
    } else {
      this.data = await lastValueFrom(
        this.db
          .doc$(`insuranceRefunds/${this.id}`)
          .pipe(
            docJoin(this.db.firestore, 'uuid', 'members'),
            docJoin(this.db.firestore, 'paymentId', 'insurancePayments'),
            take(1)
          )
      );
    }

    console.log('data!!', this.data);
  }

  //모달닫기
  closeModal() {
    this.modalController.dismiss();
  }
}
