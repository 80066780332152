import { Component, Input, OnInit } from '@angular/core';
import { Post } from 'src/app/core/models/post.model';
import { CommunityService } from 'src/app/core/services/community.service';

@Component({
  selector: 'app-common-community-card',
  templateUrl: './common-community-card.component.html',
  styleUrls: ['./common-community-card.component.scss'],
})
export class CommonCommunityCardComponent implements OnInit {
  @Input() item: Post;

  constructor(private communityService: CommunityService) {}

  ngOnInit() {
    console.log('item', this.item);
  }

  // 좋아요
  async like(post: Post) {
    if (!post['myLikeSwitch']) {
      await this.communityService.createLike(post.id);
      post.likeCount += 1;
      post['myLikeSwitch'] = true;
    } else {
      await this.communityService.deleteLike(post.id);
      post.likeCount -= 1;
      post['myLikeSwitch'] = false;
    }
  }
}
