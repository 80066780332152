// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card {
  width: 100%;
  border: 1px solid var(--gray-scale100);
  border-radius: 6px;
  display: flex;
  align-items: center;
  min-height: 6.25rem;
}

.left-thumbnail {
  width: 6.25rem;
  min-width: 6.25rem;
  height: 100%;
}
.left-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  width: calc(100% - 6.25rem);
  height: 100%;
  padding: 0.75rem;
  border-left: 1px solid var(--gray-scale100);
}
.text .name {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: var(--gray-scale900);
  margin-bottom: 0.5rem;
}

.row-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
}
.row-wrap .title {
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--gray-scale500);
}
.row-wrap .txt {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--gray-scale750);
  text-align: right;
  white-space: pre-line;
  word-break: keep-all;
}

.row-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/shared/small-info-card/small-info-card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sCAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAGA;EACE,2BAAA;EACA,YAAA;EACA,gBAAA;EACA,2CAAA;AAAF;AAEE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;EACA,qBAAA;AAAJ;;AAIA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,WAAA;AADF;AAGE;EACE,2BAAA;EAAA,sBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;AADJ;AAIE;EACE,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,2BAAA;EACA,iBAAA;EACA,qBAAA;EACA,oBAAA;AAFJ;;AAKA;EACE,mBAAA;EACA,uBAAA;EACA,gBAAA;AAFF","sourcesContent":[".info-card {\n  width: 100%;\n  border: 1px solid var(--gray-scale100);\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  min-height: 6.25rem;\n}\n\n.left-thumbnail {\n  width: 6.25rem;\n  min-width: 6.25rem;\n  height: 100%;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\n.text {\n  width: calc(100% - 6.25rem);\n  height: 100%;\n  padding: 0.75rem;\n  border-left: 1px solid var(--gray-scale100);\n\n  .name {\n    font-size: 1rem;\n    line-height: 1.5rem;\n    font-weight: 600;\n    color: var(--gray-scale900);\n    margin-bottom: 0.5rem;\n  }\n}\n\n.row-wrap {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  gap: 0.5rem;\n\n  .title {\n    min-width: fit-content;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 400;\n    color: var(--gray-scale500);\n  }\n\n  .txt {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 500;\n    color: var(--gray-scale750);\n    text-align: right;\n    white-space: pre-line;\n    word-break: keep-all;\n  }\n}\n.row-ellipsis {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
