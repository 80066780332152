import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpertService {
  public timerStr: string;
  data: any = {};
  portfolio: any[] = [];

  constructor() {}

  setSelectedServices(data: any) {
    this.data = { ...this.data, ...data };
  }

  getSelectedServices() {
    return this.data;
  }

  // 포트폴리오 추가
  setPortfolio(portfolio: any) {
    this.portfolio.push(portfolio);
  }

  // 포트폴리오 가져오기
  getPortfolio() {
    return this.portfolio;
  }

  // 포트폴리오 목록 설정
  setPortfolioList(portfolioList: any[]) {
    this.portfolio = portfolioList;
  }

  // 데이터 초기화
  clearData() {
    this.data = {};
    this.portfolio = [];
    // this._sendCodeNumber = false;
  }
}
