import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Notice } from 'src/app/core/models/notice.model';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-notice-detail',
  templateUrl: './notice-detail.component.html',
  styleUrls: ['./notice-detail.component.scss'],
})
export class NoticeDetailComponent implements OnInit {
  notice: Notice;

  constructor(private activatedRoute: ActivatedRoute, private db: DbService) {}

  async ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams['id'];
    this.notice = await this.db.toDoc$(`notices/${id}`);
  }
}
