import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count',
})
export class CountPipe implements PipeTransform {
  transform(value: string, maxlength): unknown {
    const count = [...new Intl.Segmenter().segment(value)].map(
      (x) => x.segment
    );
    return count?.length > maxlength ? maxlength : count?.length;
  }
}
