import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { ServiceDetailComponent } from '../../common/service-detail/service-detail.component';
import { BusinessInformationDetailComponent } from '../business-information-detail/business-information-detail.component';
import { PortfolioDetailComponent } from '../portfolio-detail/portfolio-detail.component';
import { ReviewDetailComponent } from '../../common/review-detail/review-detail.component';
import { ExpertQnaDetailComponent } from '../../common/expert-qna-detail/expert-qna-detail.component';
import { ActivatedRoute } from '@angular/router';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';
import { Member } from 'src/app/core/models/member.model';
import { lastValueFrom, take } from 'rxjs';
import { Review } from 'src/app/core/models/review.model';
import { Browser } from '@capacitor/browser';
import { AuthService } from 'src/app/core/services/auth.service';
import { Like } from 'src/app/core/models/like.model';
import { Estimate } from 'src/app/core/models/estimate.model';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-expert-detail',
  templateUrl: './expert-detail.component.html',
  styleUrls: ['./expert-detail.component.scss'],
})
export class ExpertDetailComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) ionContent: IonContent;
  member: Member;
  expert: Member;
  reviews: Review[] | any;
  like: Like;
  expertUuid: Member['uuid'];
  serviceArr: {
    name: string;
    id?: string;
    isChecked: boolean;
    imageCheck?: boolean;
  }[] = [];
  ratingDistribution;
  filteredReviews: any[] = []; // 필터 된 리뷰
  imageReviews: boolean = false; // 사진 리뷰만 필터
  price: Estimate['price']; // type === 'cost'인 경우에만

  lineSegment = '전문가정보';
  heartSwitch: boolean = false;
  type: 'myLike' | 'cost' | 'home' | 'expertSearch' | 'chat';

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private db: DbService,
    private auth: AuthService,
    private chatService: ChatService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    this.expertUuid = this.activatedRoute.snapshot.queryParams['expertUuid'];
    this.price = this.activatedRoute.snapshot.queryParams['price'];
  }

  async ionViewWillEnter() {
    this.expert = await this.db.toDoc$(`members/${this.expertUuid}`);
    this.getReviews();
    this.getLike();
    this.filteredReviews = this.reviews;
    this.serviceName(this.expert.services);
  }

  ngAfterViewInit() {
    this.scrollToSegment(this.lineSegment);
  }

  // 스크롤
  scrollToSegment(segment: string) {
    let elementId: string;
    switch (segment) {
      case '전문가정보':
        elementId = 'expertInfo';
        break;
      case '포트폴리오':
        elementId = 'portfolio';
        break;
      case '리뷰':
        elementId = 'review';
        break;
      case '궁금해요':
        elementId = 'qna';
        break;
    }

    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.offsetTop;
      this.ionContent.scrollToPoint(0, yOffset, 1000);
    }
  }

  segmentChanged(event: any) {
    this.scrollToSegment(event.detail.value);
  }

  // 리뷰 불러오기
  async getReviews() {
    this.reviews = await lastValueFrom(
      this.db
        .collection$('reviews', (ref) =>
          ref
            .where('expertUuid', '==', this.expertUuid)
            .where('isDelete', '==', false)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          leftJoinDocument(this.db.firestore, 'expertUuid', 'members'),
          take(1)
        )
    );

    this.reviewFilter({ name: '전체', isChecked: true, imageCheck: false });
    this.calculateRating();
  }

  // 좋아요 누른 업체인지 확인
  async getLike() {
    this.member = await this.auth.getUser();
    if (this.expert && this.member) {
      setTimeout(async () => {
        const id = `${this.expert.uuid}${this.member.uuid}`;
        this.like = await this.db.toDoc$(`liked/${id}`);
        if (this.like) {
          this.heartSwitch = true;
        }
      }, 100);
    }
  }

  // 평점 비율 구하기
  calculateRating() {
    // 기본값을 0으로 설정
    const count = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    if (this.reviews && this.reviews.length > 0) {
      this.reviews.forEach((review) => {
        const rating = review.rating;
        if (rating <= 1.5) {
          count[1]++;
        } else if (rating <= 2.5) {
          count[2]++;
        } else if (rating <= 3.5) {
          count[3]++;
        } else if (rating <= 4.5) {
          count[4]++;
        } else {
          count[5]++;
        }
      });
    }

    const totalReviews = this.reviews?.length || 1;
    this.ratingDistribution = {
      1: {
        count: count[1],
        percentage: (count[1] / totalReviews) * 100,
      },
      2: {
        count: count[2],
        percentage: (count[2] / totalReviews) * 100,
      },
      3: {
        count: count[3],
        percentage: (count[3] / totalReviews) * 100,
      },
      4: {
        count: count[4],
        percentage: (count[4] / totalReviews) * 100,
      },
      5: {
        count: count[5],
        percentage: (count[5] / totalReviews) * 100,
      },
    };

    if (this.reviews?.length === 0) {
      this.ratingDistribution = {
        1: { count: 0, percentage: 0 },
        2: { count: 0, percentage: 0 },
        3: { count: 0, percentage: 0 },
        4: { count: 0, percentage: 0 },
        5: { count: 0, percentage: 0 },
      };
    }
  }

  // 제공서비스 id => 이름 변환
  async serviceName(arr) {
    this.serviceArr = [{ name: '전체', isChecked: true, imageCheck: false }];
    for (const v of arr) {
      try {
        const sub = await this.db.toDoc$(`subCategories/${v}`);
        if (sub) {
          this.serviceArr.push({
            name: sub.name,
            id: sub.id,
            isChecked: false,
            imageCheck: false,
          });
        }
      } catch (error) {
        console.error(`에러가 발생했습니다.`, error);
      }
    }
  }

  // 서비스 항목에 따른 리뷰 필터
  async reviewFilter(item, index?: number, isImageCheck?: boolean) {
    if (item.name === '전체' && item.isChecked) {
      // '전체' 항목이 선택된 경우
      if (isImageCheck) {
        // 전체 데이터에서 이미지 리뷰만 필터링
        this.filteredReviews = this.reviews.filter(
          (review) => review.images?.length > 0
        );
      } else {
        // 모든 리뷰를 표시
        this.filteredReviews = this.reviews;
      }

      // '전체'를 제외한 다른 항목들 해제
      this.serviceArr.forEach((serviceItem) => {
        if (serviceItem.name !== '전체') {
          serviceItem.isChecked = false;
          serviceItem.imageCheck = false; // 이미지 체크 해제
        }
      });
    } else if (item.name !== '전체' && item.isChecked) {
      // '부분' 항목이 선택된 경우 다른 항목을 해제
      this.serviceArr.forEach((serviceItem, i) => {
        if (i !== index) {
          serviceItem.isChecked = false;
          serviceItem.imageCheck = false; // 이미지 체크 해제
        }
      });

      // 선택된 서비스로 필터링
      let filteredByService = this.reviews.filter((review) => {
        return review.subCategoryId
          ? review.subCategoryId === item.id
          : review.expertUuid?.services?.includes(item.id);
      });

      // 이미지 리뷰 체크 상태로 필터링
      this.filteredReviews = filteredByService.filter((review) => {
        return isImageCheck || this.imageReviews
          ? review.images?.length > 0
          : true;
      });

      // 선택된 항목 상태를 업데이트
      item.imageCheck = isImageCheck;

      // 선택된 서비스의 상태를 유지
      this.serviceArr.forEach((serviceItem) => {
        if (serviceItem.id === item.id) {
          serviceItem.isChecked = true;
          serviceItem.imageCheck = item.imageCheck;
        }
      });
    } else if (!item.isChecked) {
      // 선택이 해제된 경우, 필터를 다시 적용
      this.serviceArr.forEach((serviceItem) => {
        if (serviceItem.id === item.id) {
          serviceItem.imageCheck = false;
        }
      });

      // 선택된 서비스가 없는 경우 필터링
      const anyChecked = this.serviceArr.some(
        (serviceItem) => serviceItem.isChecked
      );

      if (!anyChecked) {
        if (this.imageReviews) {
          // 이미지 리뷰만 필터링
          this.filteredReviews = this.reviews.filter(
            (review) => review.images?.length > 0
          );
        } else {
          // 모든 리뷰를 표시
          this.filteredReviews = this.reviews;
        }
      }
    }
  }

  getSelectedService() {
    const selectedService = this.serviceArr.find(
      (serviceItem) => serviceItem.isChecked
    );

    if (selectedService) {
      // 선택된 서비스 항목을 반환하고, 이미지 체크 상태를 추가
      return { ...selectedService, imageCheck: this.imageReviews };
    }

    return {
      name: '전체',
      isChecked: false,
      id: null,
      imageCheck: this.imageReviews,
    };
  }

  //좋아요
  async heartActive() {
    console.log('heartSwitch', this.heartSwitch);

    if (!this.heartSwitch) {
      const updateData: Like = {
        id: `${this.expert.uuid}${this.member.uuid}`,
        dateCreated: new Date().toISOString(),
        uuid: this.member.uuid,
        type: 'expert',
        contentId: this.expert.uuid,
      };
      await this.db.updateAt(`liked/${updateData.id}`, updateData);
      this.heartSwitch = true;
      this.alertService.presentToast('좋아요한 목록에 추가했습니다.');
    } else {
      const id = `${this.expert.uuid}${this.member.uuid}`;
      this.like = await this.db.toDoc$(`liked/${id}`);
      if (this.like) {
        this.alertService
          .cancelOkBtn('좋아요한 목록에서 삭제하시겠습니까?')
          .then(async (ok) => {
            if (ok) {
              await this.db.delete(`liked/${this.like.id}`);
              this.heartSwitch = false;
              this.alertService.presentToast('좋아요한 목록에서 삭제했습니다.');
            }
          });
      }
    }
  }

  //견적 요청하기
  goApply() {
    this.alertService.cancelOkBtn('견적서를 요청하시겠습니까?').then((ok) => {
      if (ok) {
        this.navController.navigateForward('/application-address', {
          queryParams: {
            requestType: 'estimate',
            expertUuid: this.expertUuid,
          },
        });
      }
    });
  }

  //서비스 상세 설명
  async openServiceDetail(text: Member['serviceDetail']) {
    const modal = await this.modalController.create({
      component: ServiceDetailComponent,
      componentProps: { text },
    });
    await modal.present();
  }

  //사업자 정보
  async openBusinessInformation(expertInfo: Member['expertInfo']) {
    const modal = await this.modalController.create({
      component: BusinessInformationDetailComponent,
      componentProps: { expertInfo },
    });
    await modal.present();
  }

  //포트폴리오 상세
  async openPortfolioDetail(portfolio: Member['portfolio']) {
    const modal = await this.modalController.create({
      component: PortfolioDetailComponent,
      componentProps: { portfolio },
    });
    await modal.present();
  }

  //리뷰 상세
  async openReviewDetail(expertUuid: Member['uuid']) {
    const modal = await this.modalController.create({
      component: ReviewDetailComponent,
      componentProps: { expertUuid, type: 'myLike' },
    });
    await modal.present();
  }

  //궁금해요 qna
  async openExpertDetail(qnaLists: Member['qnaLists']) {
    const modal = await this.modalController.create({
      component: ExpertQnaDetailComponent,
      componentProps: { qnaLists },
    });
    await modal.present();
  }

  // 채팅하기
  goChat() {
    const requestId = this.activatedRoute.snapshot.queryParams['requestId'];
    const estimateId = this.activatedRoute.snapshot.queryParams['estimateId'];

    this.chatService
      .createChat(requestId, estimateId, this.expertUuid)
      .then(async (data) => {
        this.navController.navigateForward('/chat-detail', {
          queryParams: {
            chatId: data,
            partnerUuid: this.expertUuid,
          },
        });
      });
  }

  // 링크 이동
  goUrl(type: 'homepage' | 'insta' | 'facebook' | 'twitter' | 'blog') {
    const url =
      type === 'homepage'
        ? this.expert.links.homepage
        : type === 'insta'
        ? this.expert.links.insta
        : type === 'facebook'
        ? this.expert.links.facebook
        : type === 'twitter'
        ? this.expert.links.twitter
        : this.expert.links.blog;
    if (url) {
      Browser.open({ url });
    }
  }
}
