import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-application-complete02',
  templateUrl: './application-complete02.component.html',
  styleUrls: ['./application-complete02.component.scss'],
})
export class ApplicationComplete02Component implements OnInit {
  constructor(
    private navController: NavController,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  //확인
  goHome() {
    this.navController.navigateForward('/tabs/home');
    this.alertService.presentToast('견적 요청을 완료했습니다.');
  }
}
