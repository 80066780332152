import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { lastValueFrom, take } from 'rxjs';
import { FavoriteEstimate } from 'src/app/core/models/favorite-estimate.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, docJoin } from 'src/app/core/services/db.service';
import { ImageDetailComponent } from '../../image-detail/image-detail.component';

@Component({
  selector: 'app-my-estimate-detail',
  templateUrl: './my-estimate-detail.component.html',
  styleUrls: ['./my-estimate-detail.component.scss'],
})
export class MyEstimateDetailComponent implements OnInit {
  member: Member;
  favoriteEstimate: FavoriteEstimate[] | any;
  type: 'write' | 'mypage';

  constructor(
    private navController: NavController,
    private db: DbService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    console.log('type', this.type);
  }

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    await this.getData();
  }

  // 즐겨찾기 견적 데이터 불러오기
  async getData() {
    const id = this.activatedRoute.snapshot.queryParams['id'];
    this.favoriteEstimate = await lastValueFrom(
      this.db
        .doc$(`favoriteEstimates/${id}`)
        .pipe(docJoin(this.db.firestore, 'estimateId', 'estimates'), take(1))
    );
  }

  // 이미지 상세
  async imageDetail(data, idx: number) {
    const items = data.map((item) => ({
      image: item,
      videoCheck: false,
    }));

    const modal = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: { item: items, idx },
    });

    await modal.present();
  }

  // 수정
  goEdit() {
    this.navController.navigateForward('/my-estimate-write', {
      queryParams: {
        id: this.favoriteEstimate.id,
      },
    });
  }

  // 견적서 불러오기
  goDetail() {
    this.navController.navigateForward('/estimate-request-detail', {
      queryParams: {
        favoriteEstimateId: this.favoriteEstimate.estimateId.id,
        readType: 'bookMark',
      },
    });
  }
}
