import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  Observable,
  forkJoin,
  from,
  lastValueFrom,
  map,
  of,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { Member } from 'src/app/core/models/member.model';
import { PointHistory } from 'src/app/core/models/point-history.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
})
export class PaymentListComponent implements OnInit {
  topLimit = 12;
  member$: Observable<Member[]>;
  points$: Observable<PointHistory[] | any>;
  options: string = '전체';
  year = dayjs().get('year');
  month = dayjs().add(1, 'month').get('month');
  listOption: any = {
    header: '옵션',
    translucent: true,
  };

  constructor(private auth: AuthService, private db: DbService) {}

  ngOnInit() {
    this.member$ = this.auth.user$;
    this.getPoints();
  }

  // 포인트 내역 불러오기
  async getPoints() {
    const member = await lastValueFrom(this.member$.pipe(take(1)));
    this.points$ = this.db
      .collection$('pointHistories', (ref) =>
        ref.where('uuid', '==', member['uuid']).orderBy('dateCreated', 'desc')
      )
      .pipe(
        leftJoinDocument(this.db.firestore, 'uuid', 'members'),
        switchMap((points: any[]) => {
          // 빈값인 경우
          if (!points || points.length === 0) {
            return of([]);
          }
          const updatedPoints$ = points.map(async (point) => {
            if (
              (point.type === '포인트 사용' ||
                point.type === '보너스 포인트') &&
              point.acceptUuid
            ) {
              try {
                const memberDoc = await lastValueFrom(
                  this.db.doc$(`members/${point.acceptUuid}`).pipe(take(1))
                );
                point.acceptMember = memberDoc;
              } catch (error) {
                console.error(
                  '멤버 정보를 불러오는 중 에러가 발생했습니다.:',
                  error
                );
              }
            }
            return point;
          });
          return forkJoin(updatedPoints$).pipe(map((results) => results || []));
        }),
        startWith([]) // Set initial value to an empty array
      );

    this.points$.subscribe({
      next: (v) => {
        console.log('points$', v); // Log the observable's output
      },
      error: (e) => {
        console.error('Error in points$:', e);
      },
      complete: () => {
        console.log('points$ completed');
      },
    });
  }

  // 이전달
  downYear() {
    this.month -= 1;
    if (this.month == 0) {
      this.year -= 1;
      this.month = 12;
    }
  }

  // 다음달
  upYear() {
    this.month += 1;
    if (this.month == 13) {
      this.year += 1;
      this.month = 1;
    }
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 12;
      ev.target.complete();
    }, 500);
  }
}
