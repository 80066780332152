import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { lastValueFrom, map, take } from 'rxjs';
import { TopCategory } from 'src/app/core/models/category.model';
import { AddressService } from 'src/app/core/services/address.service';
import {
  ArrayleftJoinDocument,
  DbService,
} from 'src/app/core/services/db.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {
  @Input() type: 'service' | 'ares';
  categories: TopCategory[] | any;
  searchCategories: TopCategory[];
  selectService: string = ''; // 선택된 서비스 명
  select: boolean = false; // 서비스 선택하면 지역영역 활성화
  lineSegment = 'service';
  keyword: string = '';
  isSearch: boolean = false; // 검색 했는지 안했는지
  // 지역
  siArr = []; // 지역 '시' 배열
  guArr = []; // 지역 '구' 배열
  selectedSi: string = ''; // 선택한 지역 '시'
  selectedGu: string = ''; // 선택한 지역 '구'
  area: string = '';
  categoryType: string = ''; // 선택한 카테고리가 top인지 sub인지

  constructor(
    private modalController: ModalController,
    private db: DbService,
    private loadingService: LoadingService,
    private addressService: AddressService,
    private filterService: FilterService
  ) {}

  async ngOnInit() {
    await this.getServices();
    this.area = '';
    this.selectedSi = '서울';
    this.siArr = this.addressService.getSi().filter((si) => si !== '전국');
    this.guArr = this.addressService.getGu(this.selectedSi);

    if (this.type) {
      this.lineSegment = this.type;
    }

    const filters = this.filterService.filters;
    const filterCount = [filters.service, filters.si, filters.gu].filter(
      Boolean
    ).length;

    if (filterCount > 0) {
      this.selectedSi = filters.si;
      this.selectedGu = filters.gu;
      this.selectService = filters.service;
      this.select = true;
    }
  }

  // 대/소 카테고리 데이터 불러오기
  async getServices() {
    this.categories = this.searchCategories = await lastValueFrom(
      this.db
        .collection$('topCategories', (ref) =>
          ref.where('name', '!=', '전체보기')
        )
        .pipe(
          ArrayleftJoinDocument(this.db.firestore, 'subIds', 'subCategories'),
          map((categories: TopCategory[]) =>
            categories.sort((a, b) =>
              b.dateCreated < a.dateCreated
                ? -1
                : b.dateCreated > a.dateCreated
                ? 1
                : 0
            )
          ),
          take(1)
        )
    );
  }

  // 세그먼트 이동
  segmentChange(e) {
    if (e.detail.value === 'service') {
      this.select = false;
    }
  }

  // 선택한 서비스
  selectValue(event: any, category?: any) {
    // if (event && event.stopPropagation) {
    //   event.stopPropagation();
    // }
    if (!category.subIds || category.subIds?.length === 0) {
      this.select = true;
      this.categoryType = 'top';
      this.selectService = category.id;
    } else {
      this.select = true;
      this.categoryType = 'sub';
      this.selectService = event.detail.value;
    }

    if (this.select) {
      this.lineSegment = 'area';
    }
  }

  // '시' 선택
  setSelectSi(si) {
    this.selectedSi = si;
    this.selectedGu = '';
    this.guArr = this.addressService.getGu(this.selectedSi);
  }

  // '구' 선택
  setSelectGu(event: any) {
    this.selectedGu = event.detail.value;
    this.closeModal();
  }

  // 검색
  async goSearch(keyword: string) {
    keyword = keyword?.toLowerCase().trim();
    if (keyword) {
      await this.loadingService.load();

      // 초기화
      this.searchCategories = [];
      this.isSearch = true;
      this.select = false;

      // 검색 필터
      this.categories.forEach((val: any) => {
        // 서브 카테고리 이름이 키워드에 포함되어 있으면 추가
        if (val.subIds) {
          val.subIds
            .filter(
              (v) => !v.deleteSwitch && v.name.toLowerCase().includes(keyword)
            )
            .forEach((sub) => {
              this.searchCategories.push({
                ...sub,
                type: 'sub',
                parentName: val.name,
              });
            });
        }
      });

      // 내림차순으로 정렬
      this.searchCategories.sort((a, b) => {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      });

      // 검색결과 없을 시
      if (this.searchCategories?.length === 0) {
        this.loadingService.hide();
      }
      this.loadingService.hide();
    } else {
      this.searchCategories = this.categories;
      this.isSearch = false;
    }
  }

  // 모달 닫기
  closeModal() {
    let filter: any = this.filterService.initFilters;
    filter.service = this.selectService;
    filter.si = this.selectedSi;
    filter.gu = this.selectedGu;
    filter.categoryType = this.categoryType;
    this.filterService.filters = filter;

    this.modalController.dismiss({
      selectService: this.selectService,
      si: this.selectedSi,
      gu: this.selectedGu,
      categoryType: this.categoryType,
    });
    this.modalController.dismiss();
  }
}
