import { Component, OnInit, ViewChild } from '@angular/core';
import { CommunityService } from 'src/app/core/services/community.service';
import { ActivatedRoute } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { Post } from 'src/app/core/models/post.model';

@Component({
  selector: 'app-reply-write',
  templateUrl: './reply-write.component.html',
  styleUrls: ['./reply-write.component.scss'],
})
export class ReplyWriteComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  comment: Comment | any;
  comments: Comment[] | any;
  postWriterUuid: Post['uuid'];

  constructor(
    private activatedRoute: ActivatedRoute,
    public communityService: CommunityService
  ) {
    this.comment = this.activatedRoute.snapshot.queryParams.comment;
    this.postWriterUuid =
      this.activatedRoute.snapshot.queryParams.postWriterUuid;
  }

  ngOnInit() {
    console.log('comment', this.comment);
  }

  async ionViewWillEnter() {
    this.getComments();
  }

  // 댓글 불러오기
  async getComments(ev?) {
    if (ev === 'delete') {
      this.comment.commentCount -= 1;
    }
    this.comments = await this.communityService.getComments(
      this.comment.postId
    );
    const sameIdFilter = this.comments.filter((v) => v.id === this.comment.id);
    this.comment = sameIdFilter[0];
  }

  // 답글 쓰기
  async createComment() {
    this.communityService.comment.postId = this.comment.postId;
    await this.communityService.createComments(
      'recomment',
      this.postWriterUuid,
      this.comment.id
    );
    await this.getComments();
    this.communityService.comment.content = '';
  }
}
