import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { NavController } from '@ionic/angular';
import { DbService } from '../services/db.service';
import { doc, getDoc, Firestore } from '@angular/fire/firestore';

export const AuthGuard: CanActivateFn = async (route, state) => {
  const auth = inject(Auth);
  const navController = inject(NavController);
  const router = inject(Router);
  const firestore = inject(Firestore);
  const db = inject(DbService);

  return new Promise<boolean>((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      // 로그아웃 여부 확인
      const logoutUser = sessionStorage.getItem('logoutUser');

      // 인트로 페이지 노출 여부
      const admin: any = await db.toDoc$(`admin/intro`);

      // 비동기로 로그아웃 확인 후 인트로 페이지 이동
      if (admin.isIntro) {
        if (!user && logoutUser !== 'logoutUser') {
          await navController.navigateRoot('intro');
          resolve(false);
          return;
        } else if (!user && logoutUser === 'logoutUser') {
          navController.navigateRoot('login');
          resolve(false);
          return;
        }
      } else if (!admin.isIntro) {
        if (!user) {
          navController.navigateRoot('login');
          resolve(false);
          return;
        }
      } else if ((admin.isIntro && user) || (!admin.isIntro && user)) {
        const userStatusRef = doc(firestore, `members/${user.uid}`);
        const userStatusSnap = await getDoc(userStatusRef);
        if (
          userStatusSnap.exists() &&
          userStatusSnap.data().status === '정지'
        ) {
          navController.navigateRoot('suspension');
          resolve(false);
          return;
        } else {
          resolve(true);
        }
      }

      resolve(true);
    });
  });
};
