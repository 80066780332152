import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Faq } from 'src/app/core/models/faq.model';
import { Member } from 'src/app/core/models/member.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit {
  topLimit = 10;
  member: Member;
  faqs: Faq;

  constructor(
    private navController: NavController,
    private db: DbService,
    private auth: AuthService
  ) {}
  async ngOnInit() {
    this.member = await this.auth.getUser();
    await this.getNotices();
  }

  // 공지사항 데이터 불러오기
  async getNotices() {
    const type = this.member.isExpert ? 'expert' : 'member';
    this.faqs = await this.db.toCollection$('faqs', (ref) =>
      ref.where('type', '==', type).orderBy('dateCreated', 'desc')
    );
    console.log('faqs', this.faqs);
  }

  // 질문 상세로 이동
  goFaqDetail(id: Faq['id']) {
    this.navController.navigateForward('/faq-detail', {
      queryParams: {
        id,
      },
    });
  }

  // 인피니티 스크롤
  loadData(ev) {
    setTimeout(() => {
      this.topLimit += 10;
      ev.target.complete();
    }, 500);
  }
}
