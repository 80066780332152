import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { arrayRemove, arrayUnion, increment } from 'firebase/firestore';
import { Member } from '../models/member.model';
import { DbService, leftJoinDocument } from './db.service';
import { AuthService } from './auth.service';
import { PushService } from './push.service';
import { AlertService } from './alert.service';
import { CommonService } from './common.service';
import { SocialService } from './social.service';
import { LoadingService } from './loading.service';
import { combineLatest, lastValueFrom, map, of, switchMap, take } from 'rxjs';
import { Post } from '../models/post.model';
import { Like } from '../models/like.model';
import { Chat } from '../models/chat.model';
import * as dayjs from 'dayjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  member: Member;

  initMember: Member = {
    uuid: '',
    email: '',
    dateCreated: '',
    name: '',
    profile: '',
    point: 0,
    bonusPoint: 0,
    status: 'activity',
    suspendReason: '',
    userCode: '',
    recommendCode: '',
    loginType: [],
    exitDate: '',
    exitReason: { category: '', detail: '' },
    appAlarmSwitch: true,
    marketingSwitch: false,
    pushId: [],
    isExpert: false,
    enterChatroomId: '',
    applicationDate: '',
    isAdminApproval: false,
    isFirstLogin: true,
    approvalDate: '',
    gender: '',
    phone: '',
    services: [],
    blockUser: [],
    address: '',
    addressDetail: '',
    addressSiGu: { si: '', gu: '' },
    expertInfo: {
      companyName: '',
      ceo: '',
      businessNumber: '',
      companyAddress: '',
      companyAddressDetail: '',
      businessLicense: '',
      sido: '',
      sigungu: '',
    },
    areas: [],
    serviceDetail: '',
    activityName: '',
    introduce: '',
    career: '',
    portfolio: [],
    qnaLists: [],
    links: { homepage: '', insta: '', facebook: '', twitter: '', blog: '' },
    reviewCount: 0,
    rating: 0,
    hiredCount: 0,
    password: '',
    conrimePwd: '',
    agree: {
      personal: false,
      service: false,
      unique: false,
      mobileCarrier: false,
      adInfo: false,
    },
  };

  constructor(
    private db: DbService,
    private auth: AuthService,
    private navController: NavController,
    private pushService: PushService,
    private alertService: AlertService,
    private commonService: CommonService,
    private socialService: SocialService,
    private loadingService: LoadingService,
    public platform: Platform,
    private http: HttpClient
  ) {
    this.member = JSON.parse(JSON.stringify(this.initMember));
  }

  /**
   * 이메일 중복확인
   * @param email 유저 이메일
   * @returns
   */
  async emailCheck(email: string) {
    const members = await this.db.toCollection$('members', (ref) =>
      ref.where('status', '!=', 'exit').where('email', '==', email)
    );
    return members;
  }

  /**
   * 유저 코드 확인
   * @param code 유저 코드
   * @returns
   */
  async codeCheck(code: string) {
    const members = await this.db.toCollection$('members', (ref) =>
      ref.where('status', '!=', 'exit').where('userCode', '==', code)
    );
    return members;
  }

  /**
   * 전화번호 중복확인
   * @param phone 유저 전화번호
   * @returns
   */
  async phoneCheck(phone: string) {
    const members = await this.db.toCollection$('members', (ref) =>
      ref.where('status', '!=', 'exit').where('phone', '==', phone)
    );
    return members;
  }

  /**
   * 회원 가입
   * @param email 유저 이메일
   * @param password 유저 비밀번호
   * @param loginType 유저 로그인 타입
   * @param code 추천인코드
   * @returns
   */
  async joinAuth(
    email: string,
    password: string,
    loginType: any,
    code: string
  ) {
    await this.loadingService.load();
    const socialJoin = !this.member.loginType.includes('email'); // 로그인 타입 구분

    // 유저 확인처리
    const user = socialJoin
      ? await this.socialService.socialSignup(loginType[0], email)
      : await this.auth.registerUser({
          email,
          password,
        });

    this.member.uuid = user.user.uid;
    this.member.dateCreated = new Date().toISOString();
    this.member.loginType = socialJoin ? loginType : ['email'];
    this.member.userCode = this.generateRandomString(7);
    this.member.recommendCode = code;
    this.member.marketingSwitch = this.member.agree.adInfo;

    if (this.member) {
      // 관리자 대시보드 통계값 업데이트
      const id = dayjs().format('YYYYMMDD');
      const statisticsData = await this.db.toDoc$(`statistics/${id}`);
      await this.db.updateAt(`statistics/${id}`, {
        id,
        date: {
          year: dayjs().format('YYYY'),
          month: dayjs().format('MM'),
          date: dayjs().format('DD'),
        },
        joinMembersCount: increment(1),
        exitMembersCount: statisticsData ? statisticsData.exitMembersCount : 0,
        pointPaymentCount: statisticsData
          ? statisticsData.pointPaymentCount
          : 0,
        pointRefundCount: statisticsData ? statisticsData.pointRefundCount : 0,
        insurancePaymentCount: statisticsData
          ? statisticsData.insurancePaymentCount
          : 0,
        insuranceRefundCount: statisticsData
          ? statisticsData.insuranceRefundCount
          : 0,
      });

      // 푸시
      const pushId = await this.pushService.getId();
      if (pushId && pushId.userId) {
        // 다른계정에 나의 푸시아이디가 들어가 있는경우 빼기
        const other = await this.db.toCollection$(`members`, (ref) =>
          ref.where('pushId', 'array-contains', pushId.userId)
        );
        if (other) {
          const promise = other.map((e) => {
            if (e.uid != this.member.uuid) {
              this.db.updateAt(`members/${e.uuid}`, {
                pushId: arrayRemove(pushId.userId),
              });
            }
          });
          await Promise.all(promise);
        }
        await this.db.updateAt(`members/${this.member.uuid}`, {
          pushId: arrayUnion(pushId.userId),
        });
      }

      // 이메일 유저 로그인처리
      if (!socialJoin) {
        this.auth.loginUser({ email: this.member.email, password });
        this.auth.sendEmailVerificationUser(); // 이메일 인증
        this.auth.logoutUser();
        this.loadingService.hide();
        this.alertService
          .okBtn(
            '인증 메일이 전송되었습니다.\n 인증 메일 확인이 안될 경우 스팸 메일함을 확인해주세요.'
          )
          .then(async (ok) => {
            if (ok) {
              this.navController.navigateRoot('/login', {
                animationDirection: 'forward',
              });
            }
          });
      } else {
        this.loadingService.hide();
        delete this.member.password;
        delete this.member.conrimePwd;
        delete this.member.agree;
        if (pushId && pushId.userId) {
          this.member.pushId = [pushId.userId];
        }
        await this.db.updateAt(`members/${this.member.uuid}`, this.member);
        this.clearData();
        this.navController.navigateRoot('/tabs/home', {
          animationDirection: 'forward',
        });
      }

      this.loadingService.hide();
      delete this.member.password;
      delete this.member.conrimePwd;
      delete this.member.agree;
      await this.db.updateAt(`members/${this.member.uuid}`, this.member);
      localStorage.removeItem('confirmEmail'); // 로컬 지우기, 비밀번호 지우기
      this.clearData();
    } else {
      this.loadingService.hide();
      localStorage.removeItem('confirmEmail');
      this.clearData();
      await this.auth.exitUser();
      return;
    }

    return this.member;
  }

  /**
   * 내가 좋아요한 글 데이터 불러오기
   * @returns
   */
  async myLikePosts() {
    const member = await this.auth.getUser();
    return lastValueFrom(
      this.db
        .collection$('liked', (ref) =>
          ref.where('uuid', '==', member.uuid).orderBy('dateCreated', 'desc')
        )
        .pipe(
          switchMap(async (likes: Like[]) => {
            const myLikes = await Promise.all(
              likes.map(async (like: Like) => {
                let content: Post | Chat | Member | any;

                if (like.type === 'post') {
                  content = await this.db.toDoc$(`posts/${like.contentId}`);

                  if (content && content?.uuid) {
                    const uuid = await this.db.toDoc$(
                      `members/${content.uuid}`
                    );
                    content = { ...content, uuid, myLikeSwitch: true };
                  }
                } else if (like.type === 'chat') {
                  content = await this.db.toDoc$(`chats/${like.contentId}`);
                } else {
                  content = await this.db.toDoc$(`members/${like.contentId}`);
                  if (content?.uuid) {
                    const uuid = await this.db.toDoc$(
                      `members/${content.uuid}`
                    );
                    content = { ...content, uuid, myLikeSwitch: true };
                  }
                }

                return { ...like, content };
              })
            );
            return myLikes;
          }),
          take(1)
        )
    );
  }

  /**
   * 읽지 않은 알림 내역
   * @returns
   */
  unReadNotifications() {
    return this.db
      .collection$('notifications', (ref) =>
        ref.where('uuid', '==', this.auth.uid()).orderBy('dateCreated', 'desc')
      )
      .pipe(
        map((data) => {
          let unread = data.filter((e) => !e.checkSwitch)?.length;
          return unread;
        })
      );
  }

  /**
   * push 제거(로그아웃/탈퇴)
   * @param uuid 유저 uuid
   */
  async removePush(uuid: Member['uuid'], pushId: string) {
    await this.db.updateAt(`members/${uuid}`, {
      pushId: arrayRemove(pushId),
    });
  }

  // 내가 있는 채팅방 업데이트 (들어오기)
  async updateChatroomIn(chatId: Chat['id']) {
    await this.db.updateAt(`members/${this.auth.uid()}`, {
      enterChatroomId: chatId,
    });
  }

  // 내가 있는 채팅방 업데이트 (나가기)
  async updateChatroomOut(chatId: Chat['id']) {
    if (chatId) {
      await this.db.updateAt(`members/${this.auth.uid()}`, {
        enterChatroomId: '',
      });
    }
  }

  // 유저 코드 영문 + 숫자 랜덤 7글자 생성
  generateRandomString(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // 초기화
  clearData() {
    this.member = JSON.parse(JSON.stringify(this.initMember));
  }
}
