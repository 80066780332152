import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Intro } from 'src/app/core/models/admin.model';
import { DbService } from 'src/app/core/services/db.service';
import Swiper, { Autoplay, Pagination } from 'swiper';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
  intro: Intro;
  swiper: Swiper;
  viewSwitch: boolean = false;
  showSwitch: boolean = false;
  guardData;

  constructor(private navController: NavController, private db: DbService) {}

  async ngOnInit() {
    this.guardData = sessionStorage.getItem('logoutUser');
    console.log('guardData', this.guardData);

    if (!this.guardData) {
      await this.getData();
      this.showSwitch = true;
      setTimeout(() => {
        this.swiperOptions();
      }, 100);
    }
  }

  // 인트로 데이터 불러오기
  async getData() {
    this.intro = await this.db.toDoc$(`admin/intro`);
  }

  //스와이퍼 옵션
  async swiperOptions() {
    this.swiper = new Swiper('.introSwiper', {
      modules: [Pagination, Autoplay],
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },

      on: {
        reachEnd: () => {
          this.viewSwitch = true;
        },
        slideChange: () => {
          const isLastSlide = this.swiper.isEnd;
          this.viewSwitch = isLastSlide;
        },
      },
    });

    this.swiper.update();
  }

  //스와이퍼 다음버튼
  async onNextButtonClick() {
    this.swiper.slideNext();
  }

  //로그인
  goLogin() {
    this.navController.navigateRoot('/login', {
      animationDirection: 'forward',
    });
  }
}
