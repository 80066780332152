import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/core/models/notification.model';

@Component({
  selector: 'app-alarm-shared',
  templateUrl: './alarm-shared.component.html',
  styleUrls: ['./alarm-shared.component.scss'],
})
export class AlarmSharedComponent implements OnInit {
  @Input() notifications$: Observable<Notification[]>;

  constructor(private navController: NavController) {}

  ngOnInit() {}

  goAlarm() {
    this.navController.navigateForward('/alarm-list');
  }
}
