import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-refund-term',
  templateUrl: './refund-term.component.html',
  styleUrls: ['./refund-term.component.scss'],
})
export class RefundTermComponent implements OnInit {
  refundInfo: string = '';
  constructor(private db: DbService) {}

  async ngOnInit() {
    const admin = await this.db.toDoc$(`admin/terms`);
    this.refundInfo = admin.refund;
  }
}
