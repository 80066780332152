import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MbscEventcalendarOptions, localeKo } from '@mobiscroll/angular';

@Component({
  selector: 'app-calendar-pop',
  templateUrl: './calendar-pop.component.html',
  styleUrls: ['./calendar-pop.component.scss'],
})
export class CalendarPopComponent implements OnInit {
  today = new Date(); // 오늘
  selectedDate;
  localeKo = localeKo;
  eventSettings: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    view: {
      calendar: { type: 'month' },
    },
    onCellClick: (event) => this.onDateSelect(event),
  };
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  // 일자 선택
  onDateSelect(event: any) {
    this.selectedDate = event.date;
    this.closeModal();
  }

  // 모달닫기
  closeModal() {
    this.modalController.dismiss({
      selectedDate: this.selectedDate,
    });
  }
}
