import { Injectable } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Storage, ref, uploadString } from '@angular/fire/storage';
import { AlertService } from './alert.service';
import { Post } from '../models/post.model';
import { ImageDetailComponent } from 'src/app/pages/image-detail/image-detail.component';
import { AuthService } from './auth.service';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public cameraOption = {
    resultType: CameraResultType.DataUrl,
    source: CameraSource.Camera,
    allowEditing: false,
    quality: 60,
  };

  public galleryOption = {
    resultType: CameraResultType.DataUrl,
    source: CameraSource.Photos,
    allowEditing: false,
    quality: 60,
  };

  constructor(
    private actionSheetController: ActionSheetController,
    private storage: Storage,
    private alertService: AlertService,
    private modalController: ModalController,
    private auth: AuthService,
    private db: DbService
  ) {}

  /**
   * 이미지 올리기 액션시트
   * @param parentPath 부모 path
   * @returns
   */
  selectCamera(parentPath: string): Promise<string> {
    return new Promise(async (resolve) => {
      const actionSheet = await this.actionSheetController.create({
        buttons: [
          {
            text: '카메라',
            handler: () => {
              this.getBase64URL(true, parentPath).then((url) => {
                resolve(url);
              });
            },
          },
          {
            text: '갤러리',
            handler: () => {
              this.getBase64URL(false, parentPath).then((url) => {
                resolve(url);
              });
            },
          },
          {
            text: '취소',
            role: 'cancel',
          },
        ],
        backdropDismiss: false,
      });

      await actionSheet.present();
    });
  }

  /**
   * 채팅 카메리
   * @param parentPath 부모 path
   * @param type 'album' |'camera'
   * @returns
   */
  chatSandImage(parentPath: string, type: string): Promise<string> {
    return new Promise(async (resolve) => {
      if (type === 'album') {
        this.getBase64URL(false, parentPath).then((url) => {
          resolve(url);
        });
      } else {
        this.getBase64URL(true, parentPath).then((url) => {
          resolve(url);
        });
      }
      // const actionSheet = await this.actionSheetController.create({
      //   buttons: [
      //     {
      //       text: '카메라',
      //       handler: () => {
      //         this.getBase64URL(true, parentPath).then((url) => {
      //           resolve(url);
      //         });
      //       },
      //     },
      //     {
      //       text: '갤러리',
      //       handler: () => {
      //         this.getBase64URL(false, parentPath).then((url) => {
      //           resolve(url);
      //         });
      //       },
      //     },
      //     {
      //       text: '취소',
      //       role: 'cancel',
      //     },
      //   ],
      //   backdropDismiss: false,
      // });

      // await actionSheet.present();
    });
  }

  /**
   * 이미지 올리기 삭제 액션시트
   * @param parentPath 부모 path
   * @returns
   */
  selectCameraDel(parentPath: string): Promise<string> {
    return new Promise(async (resolve) => {
      const actionSheet = await this.actionSheetController.create({
        cssClass: 'actionsheet-custom',
        buttons: [
          {
            text: '카메라',
            handler: () => {
              this.getBase64URL(true, parentPath).then((url) => {
                resolve(url);
              });
            },
          },
          {
            text: '갤러리',
            handler: () => {
              this.getBase64URL(false, parentPath).then((url) => {
                resolve(url);
              });
            },
          },
          {
            text: '프로필 사진 삭제',
            handler: () => {
              resolve('');
            },
          },
          {
            text: '취소',
            role: 'cancel',
          },
        ],
        backdropDismiss: false,
      });

      await actionSheet.present();
    });
  }

  selectBannerCamera(parentPath: string): Promise<string> {
    return new Promise(async (resolve) => {
      const actionSheet = await this.actionSheetController.create({
        buttons: [
          {
            text: '앨범에서 사진 선택',
            handler: () => {
              this.getBase64URL(false, parentPath).then((url) => {
                resolve(url);
              });
            },
          },
          {
            text: '기본이미지로 변경',
            handler: () => {},
          },
          {
            text: '취소',
            role: 'cancel',
          },
        ],
        backdropDismiss: false,
      });

      await actionSheet.present();
    });
  }

  /**
   *  카메라/갤러리 실행 후 base64 return
   * @param camera true: 카메라/ false: 갤러리
   * @param parentPath 전달받은 부모 path
   * @returns√
   *
   */
  public getBase64URL(camera: boolean, parentPath: string) {
    return new Promise<any>(async (resolve) => {
      let imgOption = camera ? this.cameraOption : this.galleryOption;
      const image = await Camera.getPhoto(imgOption);

      const base64: any = image.dataUrl;

      const result = await this.base64SaveStorage(base64, parentPath);
      resolve(result);
    });
  }

  /**
   * base64 형식을 firebase storage에 저장
   * @param base64 getBase64URL에서 받은 이미지 주소
   * @param parentPath 전달받은 부모 path (폴더 이름)
   * @returns
   */
  base64SaveStorage(base64: string, parentPath: string) {
    return new Promise<any>((resolve) => {
      const extension = base64.split(';')[0].split('/')[1];
      const fileName = this.generateFilename() + `.${extension}`;
      const storageRef = ref(this.storage, `${parentPath}/${fileName}`);
      //스토리지 업데이트

      const uploadTask = uploadString(storageRef, base64, 'data_url');
      //파이어베이스 url 얻기
      uploadTask
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
          resolve(url);
        })
        .catch((error) => {
          console.log('error', error);
        });
    });
  }

  // /**
  //  * 파일 업로드 메서드
  //  * @param parentPath 부모 경로
  //  * @param fileNameSwitch 파일명 변경 여부
  //  * @returns Promise<string | any>
  //  */
  // async fileUpload(
  //   parentPath: string,
  //   fileNameSwitch?: boolean
  // ): Promise<{ formData: FormData; fileName: string } | any> {
  //   return new Promise((resolve) => {
  //     let inputElement = document.createElement('input');
  //     inputElement.type = 'file';
  //     inputElement.accept = '.pdf';

  //     inputElement.onchange = async (event: any) => {
  //       const file = event.target.files[0];
  //       // const formData = new FormData();
  //       // formData.append('file', file);

  //       resolve({ contentType: 'application/pdf', fileName: file.name });
  //     };

  //     inputElement.click();
  //   });
  // }
  async fileUpload(): Promise<
    { contentType: string; fileName: string; fileBlob: Blob } | any
  > {
    return new Promise((resolve, reject) => {
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = '.pdf';

      inputElement.onchange = async (event: any) => {
        const file = event.target.files[0];
        const maxSize = 10 * 1024 * 1024; // MB로 변환

        if (file.size > maxSize) {
          this.alertService.okBtn('10MB 이하의 파일만 업로드 가능합니다.');
          return;
        }

        const fileReader = new FileReader();

        fileReader.onload = () => {
          const fileBlob = new Blob([fileReader.result], {
            type: 'application/pdf',
          });
          resolve({
            contentType: 'application/pdf',
            fileName: file.name,
            fileBlob,
          });
        };

        fileReader.onerror = () => {
          reject('File reading has failed');
        };

        fileReader.readAsArrayBuffer(file);
      };

      inputElement.click();
    });
  }

  async imageUpload(): Promise<
    { contentType: string; fileName: string; fileBlob: Blob } | any
  > {
    return new Promise((resolve, reject) => {
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = '.jpg, .png';

      inputElement.onchange = async (event: any) => {
        const file = event.target.files[0];
        const maxSize = 10 * 1024 * 1024; // MB로 변환

        const fileReader = new FileReader();

        fileReader.onload = () => {
          const fileBlob = new Blob([fileReader.result], {
            type: 'image/jpeg',
          });
          resolve({
            contentType: 'image/jpeg',
            fileName: file.name,
            fileBlob,
          });
        };

        fileReader.onerror = () => {
          reject('File reading has failed');
        };

        fileReader.readAsArrayBuffer(file);
      };

      inputElement.click();
    });
  }

  // s3 업로드
  async uploadFilesToS3(fileUploadArr, s3) {
    try {
      for (const [index, fileInfo] of fileUploadArr.entries()) {
        const presignedUrl = s3.preSigned.url;

        const formData = new FormData();
        formData.append('key', s3.preSigned.fields.key);
        formData.append('bucket', s3.preSigned.fields.bucket);
        formData.append(
          'X-Amz-Algorithm',
          s3.preSigned.fields['X-Amz-Algorithm']
        );
        formData.append(
          'X-Amz-Credential',
          s3.preSigned.fields['X-Amz-Credential']
        );
        formData.append('X-Amz-Date', s3.preSigned.fields['X-Amz-Date']);
        formData.append('Policy', s3.preSigned.fields.Policy);
        formData.append(
          'X-Amz-Signature',
          s3.preSigned.fields['X-Amz-Signature']
        );
        formData.append('file', fileInfo.fileBlob);

        await fetch(presignedUrl, {
          method: 'POST',
          headers: {
            Accept: '*/*',
          },
          body: formData,
        }).then((response) => console.log('response', response));
      }

      console.log('All files uploaded successfully!');
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }

  /**
   * 파일명 생성 메서드
   * @returns string
   */
  private generateFilename(): string {
    // 파일명 생성 로직을 추가합니다. 여기서는 간단히 현재 시간을 이용한 임시 파일명 생성
    const timestamp = new Date().getTime();
    return `file_${timestamp}`;
  }

  /**
   * 특정 이미지들 스토리지에서 삭제
   * @param imgURLs 삭제할 이미지 URL 배열
   *
   * 새로 등록 : 사진 추가시 array에 push
   *  - 페이지 나갈 때: 모든 array
   *  - 저장할 때: array에서 db에 업데이트되는 이미지를 제외한 값
   *
   * 수정 : 사진 추가 & 삭제시 array에 push
   *  - 페이지 나갈 때: array에서 기존 데이터에 있는 이미지를 제외한 값
   *  - 저장할 때: array에서 db에 업데이트되는 이미지를 제외한 값
   */
  async deleteImgs(imgURLs: string[]) {
    //중복제거
    const set = new Set(imgURLs);
    const originURLs = [...set];
    //익스텐션에서 설정한 리사이즈 이미지의 크기

    //------- resize -------//
    //     //익스텐션에서 설정한 리사이즈 이미지의 크기
    //     const SIZE = '1000x1000';
    //     //리사이즈 이미지 path
    //     const resizeURLs = originURLs.map((url) => {
    //       const splitUrl = url.split('/');
    //       const path = splitUrl[splitUrl?.length - 2];
    //       const name = splitUrl[splitUrl?.length - 1].split('.');
    //       const resizeName = `${name[0]}_${SIZE}.${name[1]}`;
    //       return `${path}/resize/${resizeName}`;
    //     });

    //     await Promise.all(resizeURLs);

    //     //이미지 원본과 리사이즈 이미지의 url 합치기
    //     const allImgUrl = [...originURLs, ...resizeURLs];
    //------- resize -------//

    //     let allImgUrl = originURLs;

    //이미지 원본과 리사이즈 이미지의 url 합치기
    //     const allImgUrl = [...originURLs, ...resizeURLs];

    //     //삭제 실행
    //     for (const imgURL of allImgUrl) {
    //       try {
    //         const desertRef = ref(this.storage, imgURL);
    //         await deleteObject(desertRef);
    //       } catch (error) {
    //         throw error;
    //       }
    //     }
  }

  // generateFilename() {
  //   var length = 10;
  //   var text = '';
  //   var possible =
  //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for (var i = 0; i < length; i++) {
  //     text += possible.charAt(Math.floor(Math.random() * possible.length));
  //   }
  //   return text + new Date().valueOf();
  // }

  async imageDetail(item, type: 'profile' | 'data', idx?: number) {
    const modal = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: {
        item: type === 'data' ? item : [{ image: item, videoCheck: false }],
        idx,
      },
    });
    await modal.present();
  }
}
