import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { InsurancePayment } from 'src/app/core/models/insurance-payment.model';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-insurance-complete',
  templateUrl: './insurance-complete.component.html',
  styleUrls: ['./insurance-complete.component.scss'],
})
export class InsuranceCompleteComponent implements OnInit {
  payment: InsurancePayment;
  admin = { refund: '', info: '' };

  constructor(
    private navController: NavController,
    private db: DbService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    await this.getData();
    const admin = await this.db.toDoc$(`admin/terms`);
    this.admin = {
      refund: admin.insuranceRefund,
      info: admin.insuranceInfo,
    };
  }

  // 결제한 데이터 정보 불러오기
  async getData() {
    const id = this.activatedRoute.snapshot.queryParams['paymentId'];
    this.payment = await this.db.toDoc$(`insurancePayments/${id}`);
    console.log('payment', this.payment);
  }

  // [확인]
  goInsuranceList() {
    this.navController.navigateRoot('insurance-list', {
      animationDirection: 'forward',
    });
  }
}
