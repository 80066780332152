import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { lastValueFrom, take } from 'rxjs';
import { Estimate } from 'src/app/core/models/estimate.model';
import { FavoriteEstimate } from 'src/app/core/models/favorite-estimate.model';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DbService, docJoin } from 'src/app/core/services/db.service';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-my-estimate-write',
  templateUrl: './my-estimate-write.component.html',
  styleUrls: ['./my-estimate-write.component.scss'],
})
export class MyEstimateWriteComponent implements OnInit {
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';
  member: Member;
  data: FavoriteEstimate | any;

  memberForm = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(2)]),
    price: new FormControl(null, [
      Validators.required,
      Validators.minLength(2),
    ]),
    detail: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(1000),
    ]),
  });
  images: string[] = Array(6).fill(null);

  constructor(
    private alertService: AlertService,
    private navController: NavController,
    private activatedRoute: ActivatedRoute,
    private imgService: ImageService,
    private db: DbService,
    private commonService: CommonService,
    private auth: AuthService
  ) {}
  async ngOnInit() {
    this.member = await this.auth.getUser();
    const id = this.activatedRoute.snapshot.queryParams['id'];

    // 3자리수마다 콤마 찍히도록
    this.memberForm.get('price').valueChanges.subscribe((value) => {
      const formattedValue = this.format(value);
      this.memberForm
        .get('price')
        .setValue(formattedValue, { emitEvent: false });
    });

    if (id) {
      this.data = await lastValueFrom(
        this.db
          .doc$(`favoriteEstimates/${id}`)
          .pipe(docJoin(this.db.firestore, 'estimateId', 'estimates'), take(1))
      );
      this.memberForm.setValue({
        title: this.data.title,
        price: String(this.data.estimateId.price),
        detail: this.data.estimateId.content,
      });
      this.images = this.data.estimateId.images
        ? this.data.estimateId.images.slice(0, 6)
        : Array(6).fill(null);
    }
    if (this.images.length < 6) {
      this.images = [
        ...this.images,
        ...Array(6 - this.images.length).fill(null),
      ];
    }
  }
  //뒤로 얼럿
  backAlert() {
    this.alertService
      .cancelOkBtn('입력하신 내용이 저장되지 않습니다.\n그래도 나가시겠습니까?')
      .then((ok) => {
        if (ok) {
          this.navController.pop();
        }
      });
  }

  // 이미지 추가
  async addImg(index: number) {
    const imgUrl = await this.imgService.selectCamera('favoriteEstimates');
    this.images[index] = imgUrl;
  }

  // 이미지 삭제
  removeImg(index: number) {
    this.images[index] = null;
  }

  //저장
  save() {
    this.alertService
      .cancelOkBtn(
        `입력한 내용으로 견적서를 ${this.data ? '수정' : '저장'}하시겠습니까?`
      )
      .then(async (ok) => {
        if (ok) {
          const emptyImages = this.images.filter(
            (img) => img != null && img.trim() !== ''
          );
          const price = this.memberForm.get('price').value.replace(/,/g, '');

          console.log('this.data', this.data);

          // 견적서 업데이트
          let estimate: Estimate = {
            id: this.data
              ? this.data.estimateId.id
              : this.commonService.generateFilename(),
            uuid: this.member.uuid,
            acceptUuid: '',
            dateCreated: this.data
              ? this.data.estimateId.dateCreated
              : new Date().toISOString(),
            requestId: '',
            price: Number(price),
            content: this.memberForm.get('detail').value,
            images: emptyImages,
            isDelete: false,
            isComplete: false,
            isRead: false,
            checkSwitch: false,
            confirmEstimate: false,
            confirmDate: '',
            isRefund: false,
          };
          console.log('estimate.id', estimate.id);

          await this.db.updateAt(`estimates/${estimate.id}`, estimate);
          console.log('여기까지는 와1');

          // 즐겨찾기 견적 업데이트
          let favoriteEstimate: FavoriteEstimate = {
            id: this.data
              ? this.data.id
              : this.commonService.generateFilename(),
            dateCreated: this.data
              ? this.data.dateCreated
              : new Date().toISOString(),
            uuid: this.member.uuid,
            estimateId: this.data ? this.data.estimateId.id : estimate.id,
            title: this.memberForm.get('title').value,
          };

          await this.db.updateAt(
            `favoriteEstimates/${favoriteEstimate.id}`,
            favoriteEstimate
          );
          console.log('여기까지는 와2');
          this.navController.pop();
          this.alertService.presentToast(
            `견적서를  ${this.data ? '수정' : '저장'}했습니다.`
          );
        }
      });
  }

  // 비활성화 조건
  disabled() {
    if (
      this.memberForm.get('detail').value?.trim()?.length < 2 ||
      this.memberForm.get('title').value?.trim()?.length < 2 ||
      this.memberForm.get('price').value?.trim()?.length < 2
    ) {
      return true;
    }
  }

  format(valString: Number) {
    if (!valString) {
      return '';
    }
    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    return parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR);
  }

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/^0+/, '').replace(/\D/g, '');
    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  }
}
