import { Injectable } from '@angular/core';
import { DbService, docJoin, leftJoinDocument } from './db.service';
import { AuthService } from './auth.service';
import { combineLatest, lastValueFrom, map, of, switchMap, take } from 'rxjs';
import { Post } from '../models/post.model';
import { Like } from '../models/like.model';
import * as dayjs from 'dayjs';
import { Comment } from '../models/comment.model';
import { Chat } from '../models/chat.model';
import { Member } from '../models/member.model';
import { increment } from 'firebase/firestore';
import { CommonService } from './common.service';
import { Notification } from 'src/app/core/models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  comment: Comment = {
    id: '',
    dateCreated: '',
    postId: '',
    uuid: '',
    content: '',
    commentId: '',
    likeCount: 0,
    reportUser: [],
    isDelete: false,
    type: '',
  };

  constructor(
    private db: DbService,
    private auth: AuthService,
    private commonService: CommonService
  ) {}

  /**
   * 경로에 맞는 배너 불러오기
   * @param bannerType 배너 타입
   * @param userType 유저 타입
   * @returns
   */
  async getBanners(
    bannerType: '' | 'home' | 'post' | 'point' | 'request' | 'mypage',
    userType?: '' | 'member' | 'expert'
  ) {
    const banners = await this.db.toCollection$('banners', (ref) =>
      ref
        .where('type', '==', userType)
        .where('bannerType', '==', bannerType)
        .orderBy('index', 'asc')
    );
    return banners;
  }

  /**
   * 게시물 불러오기
   * @param filters 필터
   * @returns
   */
  async getPosts(filters?) {
    return await lastValueFrom(
      this.db
        .collection$('posts', (ref) =>
          ref.where('isDelete', '==', false).orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          // 좋아요 엮어오기
          switchMap((posts: Post[]) => {
            let likes$ = [];
            posts.forEach((post) => {
              const id = `${post.id}${this.auth.uid()}`;
              const doc$ = this.db.doc$(`liked/${id}`).pipe(
                map((like: Like) => {
                  return { ...post, myLikeSwitch: Boolean(like?.id) };
                })
              );
              likes$.push(doc$);
            });
            if (likes$.length > 0) {
              return combineLatest(likes$);
            } else {
              return of([]);
            }
          }),
          map(async (posts: Post[]) => {
            if (filters) {
              posts = posts.filter((val) => {
                // 날짜 필터
                if (filters.date?.length > 0) {
                  const postDate = dayjs(val.dateCreated).format('YYYYMMDD');
                  const filterDate = filters.date.map((date) =>
                    dayjs(date).format('YYYYMMDD')
                  );
                  return filterDate.includes(postDate);
                } else {
                  return val;
                }
              });
            }
            // 탈퇴회원 & 내가 신고한 게시물은 노출되지 않도록
            return posts
              .filter((post: Post | any) => {
                return post.uuid.status !== 'exit';
              })
              .filter(
                (post: any) =>
                  !post.reportUser.includes(this.auth.uid()) && post.id
              );
          }),
          take(1)
        )
    );
  }

  /**
   * 게시물 상세 불러오기
   * @returns
   */
  async getPostDetail(id: Post['id']) {
    return await lastValueFrom(
      this.db.doc$(`posts/${id}`).pipe(
        docJoin(this.db.firestore, 'uuid', 'members'),
        switchMap((post: Post) => {
          // 좋아요
          return this.db
            .collection$('liked', (ref) =>
              ref
                .where('contentId', '==', post.id)
                .where('uuid', '==', this.auth.uid())
            )
            .pipe(
              take(1),
              map((likes: any[]) => {
                if (likes.length > 0) {
                  post.myLikeSwitch = true;
                } else {
                  post.myLikeSwitch = false;
                }
                return post;
              })
            );
        }),
        take(1)
      )
    );
  }

  /**
   * 대 카테고리 데이터 불러오기
   * @returns
   */
  async getTopCategories(type: 'home' | 'noHome') {
    const category = await lastValueFrom(
      this.db.collection$('topCategories').pipe(
        map((val) => {
          if (type === 'noHome') {
            return val.filter((v) => v.name != '전체보기');
          } else {
            return val;
          }
        }),
        take(1)
      )
    );
    category.sort((a, b) =>
      b.dateCreated < a.dateCreated ? -1 : b.dateCreated > a.dateCreated ? 1 : 0
    );
    return category;
  }

  /**
   * 게시물에 따른 댓글 불러오기
   * @param postId 게시물 Id
   * @returns
   */
  async getComments(postId: Post['id']): Promise<Comment[]> {
    const member = await this.auth.getUser();
    return await lastValueFrom(
      this.db
        .collection$('comments', (ref) =>
          ref.where('postId', '==', postId).orderBy('dateCreated', 'asc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          // 댓글에 따른 대댓글 엮기
          map((arr: any) => {
            let comments = arr.filter((item) => item.type == 'comment'); // 댓글만 필터
            // 대댓글의 commentId와 댓글의 id가 같은것만 필터
            const commentList = comments.map((item) => {
              let recomments = arr.filter(
                (comment) =>
                  comment.type != 'comment' && comment.commentId == item.id
              );
              return { ...item, recomments };
            });
            return commentList;
          }),
          take(1)
        )
    );
  }

  /**
   * 내가 작성한 게시물 불러오기
   * @returns
   */
  async getMyPosts() {
    const member = await this.auth.getUser();
    return await lastValueFrom(
      this.db
        .collection$('posts', (ref) =>
          ref
            .where('uuid', '==', member.uuid)
            .where('isDelete', '==', false)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          // 좋아요 엮어오기
          switchMap((posts: Post[]) => {
            let likes$ = [];
            posts.forEach((post) => {
              const id = `${post.id}${this.auth.uid()}`;
              const doc$ = this.db.doc$(`liked/${id}`).pipe(
                map((like: Like) => {
                  return { ...post, myLikeSwitch: Boolean(like?.id) };
                })
              );
              likes$.push(doc$);
            });
            if (likes$.length > 0) {
              return combineLatest(likes$);
            } else {
              return of([]);
            }
          }),
          take(1)
        )
    );
  }

  /**
   * 내가 작성한 댓글 불러오기
   * @returns
   */
  async getMyComments() {
    const member = await this.auth.getUser();
    return await lastValueFrom(
      this.db
        .collection$('comments', (ref) =>
          ref
            .where('uuid', '==', member.uuid)
            .where('isDelete', '==', false)
            .orderBy('dateCreated', 'desc')
        )
        .pipe(
          leftJoinDocument(this.db.firestore, 'uuid', 'members'),
          leftJoinDocument(this.db.firestore, 'postId', 'posts'),
          map((comments: Comment[]) =>
            comments.filter(
              (comment: Comment | any) =>
                comment.postId && !comment.postId.isDelete
            )
          ),
          take(1)
        )
    );
  }

  // 댓글 생성
  async createComments(
    type: 'comment' | 'recomment',
    postWriterUuid: Post['uuid'],
    commentId?: Comment['id']
  ) {
    const member = await this.auth.getUser();
    this.comment.id = this.commonService.generateFilename();
    this.comment.dateCreated = new Date().toISOString();
    this.comment.uuid = member.uuid;
    this.comment.type = type;
    this.comment.commentId = type === 'recomment' ? commentId : '';
    try {
      await Promise.all([
        this.db.updateAt(`comments/${this.comment.id}`, this.comment),
        this.db.updateAt(`posts/${this.comment.postId}`, {
          commentCount: increment(1),
        }),
      ]);

      if (this.comment.uuid != postWriterUuid) {
        this.commentAlarm(postWriterUuid, this.comment.postId);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // 댓글 알림 전송
  async commentAlarm(postWriterUuid: Post['uuid'], postId: Comment['postId']) {
    let notifications: Notification = {
      id: this.commonService.generateFilename(),
      uuid: postWriterUuid,
      dateCreated: new Date().toISOString(),
      readSwitch: false,
      title: '커뮤니티',
      content: '게시글에 댓글이 달렸습니다. 확인해주세요.',
      url: `/post-detail?postId=${postId}`,
      checkSwitch: false,
      isAdminPush: false,
    };
    await this.db.updateAt(`notifications/${notifications.id}`, notifications);
  }

  // 댓글 삭제
  async deleteComment(commentId: Comment['id']) {
    await this.db.updateAt(`comments/${commentId}`, {
      isDelete: true,
    });
  }

  // 댓글 수정
  async updateComment(comment: Comment, content: string) {
    await this.db.updateAt(`comments/${comment.id}`, {
      content,
    });
  }

  // 게시물 좋아요 생성
  async createLike(contentId: Post['id']) {
    const member = await this.auth.getUser();
    const updateData: Like = {
      id: `${contentId}${member.uuid}`,
      dateCreated: new Date().toISOString(),
      uuid: member.uuid,
      type: 'post',
      contentId: contentId,
    };

    await Promise.all([
      this.db.updateAt(`liked/${updateData.id}`, updateData),
      this.db.updateAt(`posts/${contentId}`, {
        likeCount: increment(1),
      }),
    ]);
  }

  // 게시물 좋아요 삭제
  async deleteLike(contentId: Post['id']) {
    const member = await this.auth.getUser();
    const id = `${contentId}${member.uuid}`;

    await Promise.all([
      this.db.delete(`liked/${id}`),
      this.db.updateAt(`posts/${contentId}`, {
        likeCount: increment(-1),
      }),
    ]);
  }
}
