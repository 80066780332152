import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Member } from 'src/app/core/models/member.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService, leftJoinDocument } from 'src/app/core/services/db.service';
import { Request } from 'src/app/core/models/request.model';
import { lastValueFrom, map, take } from 'rxjs';
import { SubCategory } from 'src/app/core/models/category.model';
@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent implements OnInit {
  member: Member;
  requests: Request[] | any;

  constructor(
    private navController: NavController,
    private alertService: AlertService,
    private db: DbService,
    private auth: AuthService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    this.member = await this.auth.getUser();
    await this.getRequests();
  }

  // 거래내역 데이터 불러오기
  // async getRequests() {
  //   const requestsSnapshot = await lastValueFrom(
  //     this.db
  //       .collection$('requests', (ref) =>
  //         ref
  //           .where('expertUuid', '==', this.member.uuid)
  //           .where('isDelete', '==', false)
  //           .where('dealStatus', '==', '거래 완료')
  //           .orderBy('confirmDealDate', 'desc')
  //       )
  //       .pipe(
  //         leftJoinDocument(this.db.firestore, 'uuid', 'members'),
  //         leftJoinDocument(this.db.firestore, 'topCategoryId', 'topCategories'),
  //         leftJoinDocument(this.db.firestore, 'subCategoryId', 'subCategories'),
  //         map((requests: Request[]) =>
  //           requests.filter(
  //             (request: Request) =>
  //               !request.deleteExperts.includes(this.member.uuid)
  //           )
  //         ),
  //         take(1)
  //       )
  //   );

  //   // 각 요청에 대해 전문가 정보를 처리
  //   this.requests = [];
  //   for (const request of requestsSnapshot) {
  //     if (request.expertUuid) {
  //       try {
  //         // 전문가 정보를 가져오기
  //         const expertDoc = await lastValueFrom(
  //           this.db.doc$(`members/${request.expertUuid}`).pipe(take(1))
  //         );
  //         const expert = expertDoc as Member | null | any;

  //         if (expert?.services?.length > 0) {
  //           // 서비스 정보를 가져와서 전문가 객체에 설정
  //           const subCategoryNames: string[] = [];
  //           for (const serviceId of expert.services) {
  //             try {
  //               const subCategoryDoc = await lastValueFrom(
  //                 this.db.doc$(`subCategories/${serviceId}`).pipe(take(1))
  //               );
  //               const subCategory = subCategoryDoc as SubCategory | null;
  //               subCategoryNames.push(subCategory?.name || 'Unknown');
  //             } catch (error) {
  //               subCategoryNames.push('Unknown');
  //             }
  //           }
  //           expert.services = subCategoryNames.join('/'); // 서비스 이름끼리 엮기
  //           request.expertUuid = expert;
  //         } else {
  //           request.expertUuid = expert;
  //         }
  //       } catch (error) {
  //         console.error('데이터를 불러오는 중 에러가 발생했습니다.:', error);
  //       }
  //     }
  //     this.requests.push(request);
  //   }

  //   console.log('requests', this.requests);
  // }

  async getRequests() {
    try {
      const requestsSnapshot = await lastValueFrom(
        this.db
          .collection$('requests', (ref) =>
            ref
              .where('expertUuid', '==', this.member.uuid)
              .where('isDelete', '==', false)
              .where('dealStatus', '==', '거래 완료')
              .orderBy('confirmDealDate', 'desc')
          )
          .pipe(
            leftJoinDocument(this.db.firestore, 'uuid', 'members'),
            leftJoinDocument(
              this.db.firestore,
              'topCategoryId',
              'topCategories'
            ),
            leftJoinDocument(
              this.db.firestore,
              'subCategoryId',
              'subCategories'
            ),
            map((requests: Request[]) =>
              requests.filter(
                (request: Request) =>
                  !request.deleteExperts.includes(this.member.uuid)
              )
            ),
            take(1)
          )
      );

      // 병렬로 전문가 정보를 가져오기
      const requestsWithExperts = await Promise.all(
        requestsSnapshot.map(async (request) => {
          if (request.expertUuid) {
            try {
              // 전문가 정보를 가져오기
              const expertDoc = await lastValueFrom(
                this.db.doc$(`members/${request.expertUuid}`).pipe(take(1))
              );
              const expert = expertDoc as Member | null | any;

              if (expert?.services?.length > 0) {
                // 서비스 정보를 병렬로 가져오기
                const subCategoryDocs = await Promise.all(
                  expert.services.map((serviceId) =>
                    lastValueFrom(
                      this.db.doc$(`subCategories/${serviceId}`).pipe(take(1))
                    )
                  )
                );

                const subCategoryNames = subCategoryDocs.map(
                  (doc) => (doc as SubCategory | null)?.name || 'Unknown'
                );

                expert.services = subCategoryNames.join('/'); // 서비스 이름끼리 엮기
              }
              request.expertUuid = expert;
            } catch (error) {
              console.error(
                '데이터를 불러오는 중 에러가 발생했습니다.:',
                error
              );
            }
          }
          return request;
        })
      );

      this.requests = requestsWithExperts;

      console.log('requests', this.requests);
    } catch (error) {
      console.error('요청 데이터를 불러오는 중 에러가 발생했습니다.:', error);
    }
  }

  //견적 요청 정보 확인
  goDetail(requestId: Request['id'], serviceName: string) {
    this.navController.navigateForward('/estimate-request-detail', {
      queryParams: { requestId, serviceName, readType: 'readType' },
    });
  }
}
