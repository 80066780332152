import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ActionSheetController,
  IonContent,
  ModalController,
  NavController,
} from '@ionic/angular';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReviewWriteComponent } from '../../user/review-write/review-write.component';
import { ImageDetailComponent } from '../../image-detail/image-detail.component';
import { ChatService } from 'src/app/core/services/chat.service';
import { DbService, docJoin } from 'src/app/core/services/db.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { App } from '@capacitor/app';
import { MemberService } from 'src/app/core/services/member.service';
import { Member } from 'src/app/core/models/member.model';
import { Chat } from 'src/app/core/models/chat.model';
import { BehaviorSubject, Subscription, lastValueFrom, map, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Like } from 'src/app/core/models/like.model';
import { CallNumber } from 'capacitor-call-number';
import * as dayjs from 'dayjs';
import { Estimate } from 'src/app/core/models/estimate.model';
import { ImageService } from 'src/app/core/services/image.service';
import { SubCategory } from 'src/app/core/models/category.model';
import { Request } from 'src/app/core/models/request.model';
import { increment } from 'firebase/firestore';
import { PushService } from 'src/app/core/services/push.service';
import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss'],
})
export class ChatDetailComponent implements OnInit {
  @ViewChild('sendInput', { static: false }) sendInput: {
    setFocus: () => void;
  };
  @ViewChild('hiddenInput', { static: false }) hiddenInput: {
    setFocus: () => void;
  };

  @ViewChild('scrollEl', { static: false }) scrollEl: ElementRef;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  showFab: boolean = false; // fab 표시

  member: Member;
  like: Like;
  chatId: Chat['id']; // 채팅방 id
  messageTxt = ''; // 입력하는 메세지 내용
  estimate: Estimate | any; // 견적서

  chats$: BehaviorSubject<Chat>; // 채팅방 정보
  chatSub: Subscription;
  chat: Chat;
  pauseSwitch: boolean;
  isWeb: boolean;

  //커뮤니티 하트
  heartSwitch: boolean = false;
  hideSwitch: boolean = false;

  // 전문가 - 비활성화를 위해
  dealRequest: boolean = false;
  reviewRequest: boolean = false;

  // 회원 - 비활성화를 위해
  memberDealRequest: boolean = false;
  memberReviewRequest: boolean = false;
  memberInsurancePayment: boolean = false; // [거래하기] -> [보험료 결제] 버튼명

  constructor(
    private alertService: AlertService,
    private actionSheetController: ActionSheetController,
    private navController: NavController,
    private modalController: ModalController,
    public chatService: ChatService,
    private db: DbService,
    private auth: AuthService,
    private memberService: MemberService,
    private activatedRoute: ActivatedRoute,
    private imageService: ImageService,
    private pushService: PushService
  ) {}

  ngOnInit() {
    this.chatId = this.activatedRoute.snapshot.queryParams['chatId'];

    if (!Capacitor.isNativePlatform()) {
      this.isWeb = true;
    } else {
      this.isWeb = false;
    }
  }

  async ionViewWillEnter() {
    // 앱 상태 감지
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        if (window.location.pathname === '/chat-detail') {
          this.memberService.updateChatroomIn(this.chatId);
        }
      } else {
        this.memberService.updateChatroomOut(this.chatId);
      }
    });

    this.member = await this.auth.getUser();
    this.chats$ = await this.chatService.chatInit(this.chatId);
    this.chatSub = this.chats$.subscribe(async (chat) => {
      console.log('chat : ', chat);

      if (!this.chat || this.chat.messages.length !== chat.messages.length) {
        this.scrollBottom();
      }

      this.chat = chat;
      console.log('chat', this.chat);

      this.setReadIndex();
    });
    this.getLike();
    this.memberService.updateChatroomIn(this.chatId);
    this.sendEstimates(this.chat.estimateId);

    const insurance = await this.confirmInsurancePayment();
    console.log('insurance', insurance);

    // 해당 요청서에 대한 리뷰내역이 존재하는지 확인
    const review = await this.db.toCollection$('reviews', (ref) =>
      ref
        .where('isDelete', '==', false)
        .where('requestId', '==', this.chat.requestId)
    );
    // 해당 요청건에 대한 리뷰를 더이상 요청하지 못하도록 비활성화 처리
    if (review?.length > 0) {
      this.reviewRequest = true;
      this.memberReviewRequest = true;
    }

    // 고객이 거래하기 눌렀을 경우 전문가가 요청하지 못하도록 비활성화 처리
    if (this.chat.request.confirmDealDate != '' && this.member.isExpert) {
      this.dealRequest = true;
    }

    // 거래 완료 후 보험료는 결제하지 않은 경우
    if (this.chat.request.confirmDealDate != '' && !this.member.isExpert) {
      this.memberInsurancePayment = true;
    }

    // 거래 완료 & 보험료 결제까지 완료
    if (insurance?.length > 0 && !this.member.isExpert) {
      this.memberDealRequest = true;
    }
  }

  // 견적서안내 > 서비스 => 2개씩 끊어서 보여주기
  view(value) {
    if (value) {
      const parts = value.split('/');

      // 배열을 두 개씩 묶어서 줄바꿈 문자열을 생성합니다.
      const result = parts.reduce((acc, part, index) => {
        // 짝수 인덱스일 때는 새로운 줄을 추가합니다.
        if (index % 2 === 0) {
          acc.push(part);
        } else {
          // 홀수 인덱스일 때는 현재 줄에 '/'와 함께 추가합니다.
          if (parts.length - 1 === index) {
            acc[acc.length - 1] += '/' + part;
          } else {
            acc[acc.length - 1] += '/' + part + '/';
          }
          acc.push('\n'); // 줄바꿈 추가
        }
        return acc;
      }, []);

      // 마지막 항목에서 '/'를 제거합니다.

      return result.join()?.replaceAll(',', '');
    }
  }

  ngOnDestroy(): void {
    this.memberService.updateChatroomOut(this.chatId);
    this.chatSub.unsubscribe();
    this.chatService.clearChat();
  }

  ionViewDidLeave() {
    this.memberService.updateChatroomOut(this.chatId);
    this.chatSub.unsubscribe();
    this.chatService.clearChat();
  }

  // 스크롤 감지
  ngAfterViewInit() {
    this.content.getScrollElement().then((scrollElement) => {
      scrollElement.addEventListener('scroll', () =>
        this.onScroll(scrollElement)
      );
    });
  }
  onScroll(scrollElement: HTMLElement) {
    const scrollTop = scrollElement.scrollTop;
    this.showFab = scrollTop > 10;
  }

  // 좋아요 누른 상대인지 확인
  async getLike() {
    if (this.member) {
      setTimeout(async () => {
        const id = `${this.chatId}${this.member.uuid}`;
        this.like = await this.db.toDoc$(`liked/${id}`);
        if (this.like) {
          this.heartSwitch = true;
        }
      }, 100);
    }
  }

  // 회원인경우 보험료 결제 했는지([거래하기] 비활성화를 위해)
  async confirmInsurancePayment() {
    return await this.db.toCollection$('insurancePayments', (ref) =>
      ref
        .where('requestId', '==', this.chat.requestId)
        .where('isRefund', '==', false)
        .where('uuid', '==', this.member.uuid)
    );
  }

  scrollBottom() {
    setTimeout(() => {
      this.content.scrollToBottom(300);
    }, 100);
  }

  setReadIndex() {
    if (this.pauseSwitch) {
      return;
    }

    if (this.chat[this.member.uuid]['readIndex'] !== this.chat.messagesLength) {
      this.chatService.setReadIndex(this.chatId, this.chat.messagesLength);
    }
  }

  //좋아요
  async heartActive() {
    if (!this.heartSwitch) {
      const updateData: Like = {
        id: `${this.chatId}${this.member.uuid}`,
        dateCreated: new Date().toISOString(),
        uuid: this.member.uuid,
        type: 'chat',
        contentId: this.chatId,
      };
      await this.db.updateAt(`liked/${updateData.id}`, updateData);
      this.heartSwitch = true;
      this.alertService.presentToast('좋아요 목록에 추가했습니다.');
    } else {
      await this.getLike();
      this.alertService
        .cancelOkBtn('좋아요한 목록에서 삭제하시겠습니까?')
        .then(async (ok) => {
          if (ok) {
            await this.db.delete(`liked/${this.like.id}`);
            this.heartSwitch = false;
            this.alertService.presentToast('좋아요한 목록에서 삭제했습니다.');
          }
        });
    }
  }

  // 나가기 & 신고하기
  async exitReportAction() {
    const buttons = [
      {
        text: '채팅방 나가기',
        handler: () => {
          this.alertService
            .cancelOkBtn('채팅방을 나가시겠어요?')
            .then(async (ok) => {
              if (ok) {
                await this.chatService.exitChat(this.chatId);
                this.navController.pop();
                this.alertService.presentToast('채팅방을 나갔어요.');
              }
            });
        },
      },
      {
        text: '취소',
        role: 'cancel',
        handler: () => {},
      },
    ];

    // 상대방이 탈퇴한 경우가 아닐떄만 "신고하기" 버튼 추가
    if (this.chat.partner.status !== 'exit') {
      buttons.splice(1, 0, {
        text: '신고하기',
        handler: () => {
          this.alertService
            .cancelOkBtn(
              `${
                !this.member.isExpert ? '전문가를' : '고객을'
              } 신고하시겠습니까?`
            )
            .then((ok) => {
              if (ok) {
                this.navController.navigateForward('/post-report', {
                  queryParams: {
                    type: !this.member.isExpert ? 'expert' : 'member',
                    chatId: this.chatId,
                  },
                });
              }
            });
        },
      });
    }

    const actionSheet = await this.actionSheetController.create({
      buttons: buttons,
    });

    await actionSheet.present();
  }

  // 리뷰
  openReviewWrite() {
    // 회원인 경우
    if (!this.member.isExpert) {
      // 거래 확정 했을경우
      if (this.chat.request.confirmDealDate) {
        this.ReviewWriteModal(
          this.chat.partner.uuid,
          this.chat.requestId,
          this.chat.request.subCategoryId
        );
      } else {
        this.alertService.okBtn('거래 확정 시 리뷰를 작성 할 수 있습니다.');
        return;
      }
    }
    // 전문가인 경우
    else {
      // 거래 확정 됐을경우
      if (this.chat.request.confirmDealDate) {
        this.alertService
          .cancelOkBtn(
            `${this.chat.partner.name}님에게 리뷰를 요청하시겠습니까?`
          )
          .then(async (ok) => {
            if (ok) {
              await this.chatService.sendMessage(
                this.chatId,
                'requestReview',
                '리뷰 요청'
              );
              this.reviewRequest = true;
            }
          });
      } else {
        this.alertService.okBtn(
          `${this.chat.partner.name}님이 거래 확정 시 리뷰를 요청할 수 있습니다.`
        );
        return;
      }
    }
  }

  // 회원 - 리뷰작성
  async ReviewWriteModal(
    expertUuid: Member['uuid'],
    requestId: Request['id'],
    subCategoryId: SubCategory['id']
  ) {
    const modal = await this.modalController.create({
      component: ReviewWriteComponent,
      componentProps: { expertUuid, requestId, subCategoryId, type: 'chat' },
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.memberReviewRequest = true;
    }
  }

  // 거래
  goInsurancePayment() {
    if (!this.member.isExpert) {
      // 상대방이 이용정지 당한 경우 거래하지 못하도록
      if (this.chat.partner.status === 'suspend') {
        this.alertService.okBtn('이용정지된 회원으로 거래가 불가합니다.');
        return;
      }

      // [거래확정] 클릭한 경우
      if (!this.memberInsurancePayment) {
        this.alertService
          .cancelOkBtn(
            `${
              this.chat.partner.isExpert
                ? this.chat.partner.activityName
                : this.chat.partner.name
            }님과 거래를 확정 하시겠습니까?`
          )
          .then(async (ok) => {
            if (ok) {
              await this.chatService.sendMessage(
                this.chatId,
                'confirmDeal',
                '거래 확정'
              );
              // 거래 확정 처리
              await this.db.updateAt(`requests/${this.chat.requestId}`, {
                dealStatus: '거래 완료',
                estimateStatus: '상담 진행 완료',
                expertUuid: this.chat.partner.uuid,
                estimateId: this.chat.estimateId,
                confirmDealDate: new Date().toISOString(),
              });
              await this.db.updateAt(`members/${this.chat.partner.uuid}`, {
                hiredCount: increment(1), // 전문가 고용수 증가
              });

              this.memberInsurancePayment = true; // [보험료 결제]로 버튼명 변경
              this.payAlert();
            }
          });
      }
      // [보험료 결제] 클릭한 경우
      else {
        this.navController.navigateForward('/insurance-payment', {
          queryParams: {
            expertUuid: this.chat.partner.uuid,
            requestId: this.chat.requestId,
            serviceName: this.estimate?.requestId?.subCategoryId
              ? this.estimate?.requestId?.subCategoryId?.name
              : this.estimate?.uuid?.services,
          },
        });
      }
    } else {
      this.alertService
        .cancelOkBtn(`${this.chat.partner.name}님에게 거래를 요청하시겠습니까?`)
        .then(async (ok) => {
          if (ok) {
            await this.chatService.sendMessage(
              this.chatId,
              'requestDeal',
              '거래 요청'
            );
            this.dealRequest = true;
          }
        });
    }
  }

  //보험료 결제 얼럿
  payAlert() {
    this.alertService
      .cancelOkBtn(
        '거래가 확정되었습니다.\n 보험료 결제를 원하실 경우 [확인] 버튼을 클릭해주세요.'
      )
      .then(async (ok) => {
        if (ok) {
          this.navController.navigateForward('/insurance-payment', {
            queryParams: {
              expertUuid: this.chat.partner.uuid,
              requestId: this.chat.requestId,
              serviceName: this.estimate?.requestId?.subCategoryId
                ? this.estimate?.requestId?.subCategoryId?.name
                : this.estimate?.uuid?.services,
            },
          });
        }
      });
  }

  //전문가 상세
  goExpertDetail(expertUuid: Member['uuid']) {
    this.navController.navigateForward('/expert-detail', {
      queryParams: {
        expertUuid,
        type: 'chat',
      },
    });
  }

  // 이미지 상세
  async openImgDetail(content, idx?: number, isArray?: boolean) {
    let items;
    if (isArray) {
      items = content.map((item) => ({
        image: item,
        videoCheck: false,
      }));
    }
    const modal = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: {
        item: isArray ? items : [{ image: content, videoCheck: false }],
        idx,
      },
    });
    await modal.present();
  }

  // +버튼
  cameraSwitch() {
    if (!this.hideSwitch) {
      this.hideSwitch = true;
    } else {
      this.hideSwitch = false;
    }
  }

  // 이미지 서비스
  async addImg(type: 'album' | 'camera') {
    const url = await this.imageService.chatSandImage('chat', type);
    await this.chatService.sendMessage(this.chatId, 'img', url);
    // this.sendPush();
  }

  // 텍스트 메세지 보내기
  async sendMessage() {
    this.hiddenInput.setFocus();
    setTimeout(() => {
      this.sendInput.setFocus();
    }, 10);

    if (this.messageTxt?.trim()?.length === 0) {
      return;
    }
    if (this.messageTxt) {
      await this.chatService.sendMessage(this.chatId, 'txt', this.messageTxt);

      this.sendPush();
      this.messageTxt = '';
    }
  }

  // 푸시 보내기
  sendPush() {
    if (
      this.chat.partner.appAlarmSwitch &&
      !this.chat.partner.blockUser.includes(this.member.uuid)
    ) {
      let obj = {
        content: '채팅이 도착했습니다.',
        link: `/chat-detail?chatId=${this.chatId}`,
        type: 'member',
      };
      this.pushService.memberPush(obj, this.chat.partner.uuid);
    }
  }

  // 메시지 보낸 날짜 포멧
  checkDate(msg) {
    let prev = this.chat.messages.find(
      (e) => e.index === msg.index - 1
      // &&
      //   msg.uuid != this.member.uuid &&
      //   e.type !== 'refund' &&
      //   e.type !== 'viewEstimate'
    );

    return (
      prev &&
      dayjs(prev.dateCreated).format('YYYYMMdd') !==
        dayjs(msg.dateCreated).format('YYYYMMdd')
    );
  }

  // 견적서 id를 내용으로 변환
  async sendEstimates(id: Estimate['id']) {
    this.estimate = await lastValueFrom(
      this.db.doc$(`estimates/${id}`).pipe(
        docJoin(this.db.firestore, 'requestId', 'requests'),
        docJoin(this.db.firestore, 'uuid', 'members'),
        map(async (v: any) => {
          // 선택한 서비스명이 있을경우
          if (v.requestId?.subCategoryId) {
            v.requestId.subCategoryId = await lastValueFrom(
              this.db
                .doc$(`subCategories/${v.requestId.subCategoryId}`)
                .pipe(take(1))
            );
          }
          // 없는 경우에는 전문가의 서비스명을 보여주기
          else {
            v.uuid.services = await Promise.all(
              v.uuid.services.map(async (serviceId: string) => {
                const subCategory = await lastValueFrom(
                  this.db.doc$(`subCategories/${serviceId}`).pipe(take(1))
                );

                return subCategory?.name || '';
              })
            ).then((names) => names.join('/'));
          }

          return v;
        }),
        take(1)
      )
    );
  }

  // 전화걸기
  async callStore() {
    if (!this.isWeb) {
      await CallNumber.call({
        number: this.chat.partner.phone,
        bypassAppChooser: false,
      });
    } else {
      await Clipboard.write({
        string: this.chat.partner.phone,
      });
      this.alertService.presentToast('전화번호가 복사되었습니다.');
    }
  }

  trackByFn(index) {
    return index;
  }
}
